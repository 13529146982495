import React, { useEffect } from 'react';
import MainLayout from '../../../components/MainLayout';
import { useAgendaState, useAgendaDispatch } from '../../../services/Contexts/Agenda';
import AgendaCard from '../../../components/Card/AgendaCard';
import { DAYS_TO_ADD } from '../config';

const Agenda = () => {
  const dispatchAgenda = useAgendaDispatch();
  const { agenda, loading, error } = useAgendaState();
  useEffect(() => agenda.length === 0 && dispatchAgenda({ type: 'requestAgenda' }), []);
  return (
    <MainLayout text="AGENDA">
      <div className="standalone:pt-40 pt-36 max-w-sm mx-auto">
        <div className="flex flex-col justify-center items-center font-body mt-2">
          <h2 className="text-black text-lg">
            <span className="font-semibold">{`Agenda próximos ${DAYS_TO_ADD} días:`}</span>
          </h2>
        </div>
        <div className="flex justify-center items-center font-body mt-4 space-x-10">
          <span className="text-xs text-gray-400 rounded-md p-1">
            <span className="font-bold">Total:</span>
            {' '}
            <span>{agenda && agenda.length}</span>
          </span>
          <button
            onClick={() => dispatchAgenda({ type: 'requestAgenda' })}
            type="button"
            className="border-b border-t border-blue-300 rounded-lg p-2 focus:outline-none text-sm text-gray-400"
          >
            Refrescar
          </button>
        </div>
        <div className="flex flex-col justify-center items-center font-body mt-5 pb-24">
          {agenda && !loading && !error ? agenda
            .sort((a, b) => new Date(a.fecha) - new Date(b.fecha))
            .map((item) => (
              <AgendaCard agenda={item} />
            )) : <h1> Cargando agenda... </h1>}
        </div>
      </div>
    </MainLayout>
  );
};

export default Agenda;
