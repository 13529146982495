import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router';

import FormInput from '../../components/FormInput';

import { MailIcon } from '../../assets/icons/Mail';
import { LockIcon } from '../../assets/icons/Lock';
import { CrossIcon } from '../../assets/icons/Cross';
import { HandIcon } from '../../assets/icons/Hand';

import Button from '../../components/Button';
import useAuth from '../../services/Hooks/Auth';
import ROUTES from '../../routes/routes';
import MainLayout from '../../components/MainLayout';
import InstallationPopUp from '../../components/InstallationPopUp';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const { login } = useAuth();

  useEffect(() => {
    const accessTokenFromLocal = localStorage.getItem('access_token');
    const refreshTokenFromLocal = localStorage.getItem('refresh_token');
    if (accessTokenFromLocal && refreshTokenFromLocal) navigate(ROUTES.dashboard);
  }, [localStorage]);

  return (
    <>
      <MainLayout text="" showBottomNavBar={false}>
        <div className="h-screen w-full bg-blue-100">
          <div className="flex flex-col w-full xl:max-w-xl mx-auto">
            <div className="h-screen smScreen:-mt-32 pt-40 smScreen:pt-64 w-full bg-blue-100 rounded-t-2xl flex flex-col">
              <h1 className="text-center smScreen:pt-10 pt-8 font-body font-extralight text-3xl">ACCESO</h1>
              <form className="space-y-5 smScreen:mt-5 mt-16 p-5">
                <div className="flex justify-center items-center space-x-4 p-2">
                  <MailIcon color="blue-300" />
                  <FormInput type="text" placeholder="Correo" onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className="flex justify-center items-center space-x-4 p-2">
                  <LockIcon />
                  <FormInput type="password" placeholder="Contraseña" onChange={(e) => setPassword(e.target.value)} />
                </div>
                {showError
              && (
                <div className="flex justify-center items-center space-x-3">
                  <CrossIcon />
                  <p className="text-center text-red-600 font-body text-sm">Comprueba de nuevo tus credenciales</p>
                </div>
              )}
                <div className="w-full flex pt-6">
                  <Button
                    className="bg-blue-300 focus:outline-none mr-3"
                    onClick={async () => {
                      try {
                        setLoading(true);
                        setShowError(false);
                        await login(email, password);
                        localStorage.setItem('isRegistered', true);
                        navigate(ROUTES.dashboard);
                      } catch (error) {
                        setShowError(true);
                        setLoading(false);
                      }
                    }}
                    text="Acceder"
                    loading={loading}
                  />
                  <Button
                    className="bg-blue-200 focus:outline-none hover:bg-blue-300"
                    onClick={() => navigate('/registro')}
                    text="Registrarse"
                    loading={false}
                  />
                </div>
                <Button
                  className="bg-blue-200 focus:outline-none hover:bg-blue-300"
                  text="Acceder sin registrarse"
                  onClick={() => {
                    localStorage.setItem('isRegistered', false);
                    navigate(ROUTES.dashboard);
                  }}
                />
                <div
                  className="flex justify-center items-center space-x-2 focus:outline-none w-full smScreen:pt-0"
                  onClick={() => {
                    setShowError(false);
                    navigate('/recuperarpassword');
                  }}
                  role="button"
                  onKeyDown={() => null}
                  tabIndex={0}
                  style={{ WebkitTapHighlightColor: 'transparent' }}
                >
                  <HandIcon />
                  <h1 className="font-body smScreen:text-sm">He olvidado mi contraseña</h1>
                </div>
              </form>
            </div>
          </div>
        </div>
      </MainLayout>
      <InstallationPopUp />
    </>
  );
};

export default Login;
