import React from 'react';
import { navigate } from '@reach/router';
import { PropTypes } from 'prop-types';
import { useLocationDispatch } from '../../../services/Contexts/Location';
import Locate from './GetUserLocationButton';
import ROUTES from '../../../routes/routes';

const CreateIncidentButton = ({ markers, panTo }) => {
  const dispatchLocation = useLocationDispatch();
  return (
    <>
      <button
        className="h-14 w-full bg-blue-300 mt-3 md:mt-0 font-body rounded-md disabled:opacity-50 focus:outline-none"
        type="button"
        onClick={() => {
          dispatchLocation({
            type: 'saveLocation',
            payload: {
              lat: markers[0].lat,
              lng: markers[0].lng,
            },
          });
          navigate(ROUTES.incidentsCreate);
        }}
        disabled={markers.length === 0}
      >
        Crear incidencia
      </button>
      <Locate panTo={panTo} />
    </>
  );
};

CreateIncidentButton.propTypes = {
  panTo: PropTypes.func.isRequired,
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
      time: PropTypes.date,
    }),
  ).isRequired,
};

export default CreateIncidentButton;
