import { DoLogin, DoMe } from '../api/Auth';
import { useAuthDispatch, useAuthState } from '../Contexts/Auth';

const useAuth = () => {
  const dispatch = useAuthDispatch();
  const {
    error: errorState,
    access_token,
    refresh_token,
  } = useAuthState();

  const login = async (username, password) => {
    try {
      const res = await DoLogin({ username, password });
      const { status } = res;
      const data = await res.json();
      if (status !== 200) {
        dispatch({ type: 'error' });
        return Promise.reject();
      }
      localStorage.setItem('access_token', data.access_token);
      localStorage.setItem('refresh_token', data.refresh_token);
      const resMe = await DoMe();
      const me = await resMe.json();
      if (status !== 200) {
        dispatch({ type: 'error' });
        return Promise.reject();
      }
      dispatch({
        type: 'login',
        payload: {
          access_token: data.access_token,
          refresh_token: data.refresh_token,
          email: me.user.email,
          id: me.user.id,
        },
      });
      return Promise.resolve();
    } catch (error) {
      dispatch({ type: 'error' });
      return Promise.reject();
    }
  };

  return {
    login,
    errorState,
    access_token,
    refresh_token,
  };
};

export default useAuth;
