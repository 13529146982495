import React, { useState } from 'react';
import { navigate } from '@reach/router';
import { useAlertDispatch } from '../../services/Contexts/Alert';

import FormInput from '../../components/FormInput';

import { MailIcon } from '../../assets/icons/Mail';
import { CrossIcon } from '../../assets/icons/Cross';

import Button from '../../components/Button';
import MainLayout from '../../components/MainLayout';
import InstallationPopUp from '../../components/InstallationPopUp';
import { DoResetPassword } from '../../services/api/Auth';

const RecoverPassword = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const dispatchAlert = useAlertDispatch();
  const [disableButton, setDisableButton] = useState(false);

  const onClickRequestButton = async () => {
    try {
      setDisableButton(true);
      setLoading(true);
      setShowError(false);
      const response = await DoResetPassword({ email });
      if (response.ok) {
        dispatchAlert({
          type: 'enableAlert',
          payload: {
            alertText: 'Se ha enviado un correo con las instrucciones para resetear su contraseña. En caso de no recibir el correo vuelva a intentarlo pasado 5 minutos o contacta con app@aytolarinconada.es',
            alertColor: 'bg-blue-200',
          },
        });
        setTimeout(() => { navigate('/inicio'); }, 6000);
      } else {
        setDisableButton(false);
        setShowError(true);
      }
      setLoading(false);
    } catch (error) {
      setDisableButton(false);
      setShowError(true);
      setLoading(false);
    }
  };

  return (
    <>
      <MainLayout text="" showBottomNavBar={false}>
        <div className="h-screen w-full bg-blue-100">
          <div className="flex flex-col w-full xl:max-w-xl mx-auto">
            <div className="h-screen smScreen:-mt-32 pt-40 smScreen:pt-64 w-full bg-blue-100 rounded-t-2xl flex flex-col">
              <h1 className="text-center smScreen:pt-10 pt-8 font-body font-extralight text-3xl">Solicitud de cambio de contraseña</h1>
              <form className="space-y-5 smScreen:mt-5 mt-16 p-5">
                <div className="flex justify-center items-center space-x-4 p-2">
                  <MailIcon color="blue-300" />
                  <FormInput type="email" placeholder="Correo" onChange={(e) => setEmail(e.target.value)} />
                </div>
                {showError
                  && (
                    <div className="flex justify-center items-center space-x-3">
                      <CrossIcon />
                      <p className="text-center text-red-600 font-body text-sm">Hubo algún problema, compruebe su correo</p>
                    </div>
                  )}
                <div className="w-full flex pt-6">
                  <Button
                    className="bg-blue-300 focus:outline-none mr-3"
                    onClick={() => onClickRequestButton()}
                    text="Solicitar"
                    loading={loading}
                    disabled={disableButton}
                  />
                  <Button
                    className="bg-blue-200 focus:outline-none mr-3"
                    onClick={async () => {
                      navigate('/inicio');
                    }}
                    text="Atrás"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </MainLayout>
      <InstallationPopUp />
    </>
  );
};

export default RecoverPassword;
