import React from 'react';
import MainLayout from '../../../components/MainLayout';
import { DocumentIcon } from '../../../assets/icons/Document';

const BusSchedule = () => (
  <MainLayout text="HORARIOS AUTOBUSES">
    <div className="h-screen p-5 w-screen max-w-4xl mx-auto flex flex-col pt-40 standalone:pt-44 items-center smScreen:mt-2 sm:mt-8" style={{ WebkitTapHighlightColor: 'transparent' }}>
      <div className="container mx-auto smScreen:mt-5 mt-2 smScreen:pb-5 pb-2">
        <div className="flex flex-col space-y-2 font-body">
          <h1 className="font-bold"> Verano </h1>
          <div className="px-3 space-y-2 pb-5">
            <h1 className="font-light text-sm"> Horario diarios </h1>
            <div className="border border-blue-300 p-3 rounded-md flex justify-between items-center">
              <a href="/Docs/Verano-laborable.pdf" target="_black" className="text-sm"> Pulse para abrir PDF </a>
              <DocumentIcon />
            </div>
            <h1 className="font-light text-sm"> Horario festivos </h1>
            <div className="border border-blue-300 p-3 rounded-md flex justify-between items-center">
              <a href="/Docs/Verano-festivo.pdf" target="_black" className="text-sm"> Pulse para abrir PDF </a>
              <DocumentIcon />
            </div>
          </div>
          <h1 className="font-bold"> Invierno </h1>
          <div className="px-3 space-y-2">
            <h1 className="font-light text-sm"> Horario diarios </h1>
            <div className="border border-blue-300 p-3 rounded-md flex justify-between items-center">
              <a href="/Docs/Invierno-laborable.pdf" target="_black" className="text-sm"> Pulse para abrir PDF </a>
              <DocumentIcon />
            </div>
            <h1 className="font-light text-sm"> Horario festivos </h1>
            <div className="border border-blue-300 p-3 rounded-md flex justify-between items-center">
              <a href="/Docs/Invierno-festivo.pdf" target="_black" className="text-sm"> Pulse para abrir PDF </a>
              <DocumentIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  </MainLayout>
);

export default BusSchedule;
