import React from 'react';
import { navigate } from '@reach/router';
import { WarningIcon } from '../../../assets/icons/Warning';
import { Home } from '../../../assets/icons/Home';
import { ArrowLeft } from '../../../assets/icons/ArrowLeft';
import ROUTES from '../../../routes/routes';

const index = () => (
  <div className="flex max-w-5xl mx-auto items-center justify-between px-14 h-24 standalone:h-28 inset-x-0 fixed bottom-0 rounded-md shadow-inner-lg bg-white">
    <div className="flex flex-col space-y-2 justify-center items-center focus:outline-none" onClick={() => navigate(-1)} role="button" onKeyDown={() => {}} tabIndex={0}>
      <ArrowLeft />
      <span className="font-body text-sm"> Atrás </span>
    </div>
    <div className="flex flex-col space-y-2 justify-center items-center focus:outline-none" onClick={() => navigate(ROUTES.incidentList)} role="button" onKeyDown={() => {}} tabIndex={0}>
      <WarningIcon size={6} />
      <span className="font-body text-sm"> Incidencias </span>
    </div>
    <div className="flex flex-col space-y-2 justify-center items-center focus:outline-none" onClick={() => navigate(ROUTES.dashboard)} role="button" onKeyDown={() => {}} tabIndex={0}>
      <Home />
      <span className="font-body text-sm"> Inicio </span>
    </div>
  </div>
);

export default index;
