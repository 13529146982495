import React from 'react';

import { AlertIcon } from '../../assets/icons/Alert';
import { useAlertState, useAlertDispatch } from '../../services/Contexts/Alert';

const index = () => {
  const dispatchAlert = useAlertDispatch();
  const { alertStatus, alertText, alertColor } = useAlertState();
  return (
    <div
      className="absolute w-full max-w-2xl mx-auto top-0 inset-x-0 z-20 p-3"
      role="button"
      onClick={() => dispatchAlert({ type: 'disableAlert' })}
      onKeyDown={() => null}
      tabIndex={0}
      style={{ WebkitTapHighlightColor: 'transparent' }}
    >
      { alertStatus
        ? (
          <div className={`w-full ${alertColor} h-26 rounded-lg shadow-md p-5 flex justify-center items-center space-x-2 standalone:mt-12`}>
            <AlertIcon />
            <h2 className="font-body text-center">
              { alertText }
            </h2>
          </div>
        )
        : null}
    </div>
  );
};

export default index;
