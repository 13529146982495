import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router';
import {
  DoGetTelephone, DoMe, DoRoleMe, DoUpdateMe, DoUpdateMePassword, DoUpdateTelephoneMe,
} from '../../services/api/Auth';
import { useAlertDispatch } from '../../services/Contexts/Alert';
import { useAuthDispatch, useAuthState } from '../../services/Contexts/Auth';
import { useIncidentDispatch } from '../../services/Contexts/Incident';
import ROUTES from '../../routes/routes';

const Registered = () => {
  const {
    email, name, surname, id, telephone,
  } = useAuthState();
  const dispatchMe = useAuthDispatch();
  const dispatchAlert = useAlertDispatch();
  const dispatchIncident = useIncidentDispatch();

  const [enableCredentialsMod, setEnableCredentialsMod] = useState(false);
  const [enablePassMod, setEnablePassMod] = useState(false);

  const [updateMe, setUpdateMe] = useState(false);
  const [error, setError] = useState(false);

  const [updatedCredentials, setUpdatedCredentials] = useState({
    email: '',
    name: '',
    surname: '',
    telephone: '',
    oldPassword: '',
    newPassword: '',
  });

  const [entityId, setEntityId] = useState();

  useEffect(() => {
    const getCredentials = async () => {
      try {
        const data = await DoMe();
        const me = await data.json();
        const phoneData = await DoGetTelephone({ id: me.user.id });
        const phone = await phoneData.json();
        setEntityId(phone[0].id);
        dispatchMe({
          type: 'me',
          payload: {
            id: me.user.id,
            name: me.user.name,
            surname: me.user.surname,
            email: me.user.email,
            telephone: phone.length && phone[0].telephone,
          },
        });
        setUpdateMe(true);
      } catch (err) {
        dispatchAlert({ type: 'enableStandardErrorAlert' });
      }
    };
    if (!updateMe) getCredentials();
  }, [updateMe]);

  const updateUserCrenditals = async () => {
    if (!email) setUpdateMe(false);
    try {
      const role = await DoRoleMe({ id });
      const resRole = await role.json();
      await DoUpdateMe({
        id,
        email: updatedCredentials.email ? updatedCredentials.email : email,
        name: updatedCredentials.name ? updatedCredentials.name : name,
        surname: updatedCredentials.surname ? updatedCredentials.surname : surname,
        role: resRole.roles[0].id,
      });
      if (updatedCredentials.telephone) {
        await DoUpdateTelephoneMe({ entityId, telephone: updatedCredentials.telephone });
      }
      setUpdatedCredentials({
        email: '',
        name: '',
        surname: '',
        telephone: '',
        oldPassword: '',
        newPassword: '',
      });
      dispatchAlert({
        type: 'enableAlert',
        payload: {
          alertText: 'Ha modificado sus credenciales correctamente.',
          alertColor: 'bg-green-200',
        },
      });
      setError(false);
      setUpdateMe(false);
      navigate(ROUTES.dashboard);
    } catch (err) {
      setUpdatedCredentials({
        email: '',
        name: '',
        surname: '',
        telephone: '',
        oldPassword: '',
        newPassword: '',
      });
      dispatchAlert({ type: 'enableStandardErrorAlert' });
    }
  };

  const updateUserPassword = async () => {
    if (enablePassMod) {
      if (updatedCredentials.oldPassword !== '' && updatedCredentials.newPassword !== '' && updatedCredentials.oldPassword !== updatedCredentials.newPassword && updatedCredentials.newPassword.length > 8) {
        try {
          DoUpdateMePassword({
            oldPassword: updatedCredentials.oldPassword,
            newPassword: updatedCredentials.newPassword,
          });
          setUpdatedCredentials({
            email: '',
            name: '',
            surname: '',
            telephone: '',
            oldPassword: '',
            newPassword: '',
          });
          dispatchAlert({
            type: 'enableAlert',
            payload: {
              alertText: 'La contraseña ha sido modificada.',
              alertColor: 'bg-green-200',
            },
          });
          setEnablePassMod(false);
          setUpdateMe(false);
          setError(false);
          navigate(ROUTES.dashboard);
        } catch (err) {
          dispatchAlert({ type: 'enableStandardErrorAlert' });
        }
      } else if (updatedCredentials.oldPassword === ''
        && updatedCredentials.newPassword === '') {
        setError(false);
        setEnablePassMod(false);
      } else {
        setError(true);
      }
    } else {
      setEnablePassMod(!enablePassMod);
    }
  };
  return (
    <div className="h-screen w-full flex flex-col items-center sm:justify-center pt-36 standalone:pt-44 smScreen:pt-40">
      <div className="w-full max-w-3xl flex flex-col space-y-3 justify-center items-center px-8 font-body">

        <div className="flex flex-col w-full space-y-2 bg-white">
          <h3 className=""> Correo </h3>
          <input onChange={(e) => setUpdatedCredentials({ ...updatedCredentials, email: e.target.value })} value={updatedCredentials.email} disabled={!enableCredentialsMod} placeholder={email && email} type="text" className="w-full border-b border-blue-300 text-center p-2 rounded-md focus:outline-none focus:border-blue-400" />
        </div>

        <div className="flex flex-col w-full space-y-2">
          <h3 className=""> Nombre </h3>
          <input onChange={(e) => setUpdatedCredentials({ ...updatedCredentials, name: e.target.value })} value={updatedCredentials.name} disabled={!enableCredentialsMod} placeholder={name && name} type="text" className="w-full border-b border-blue-300 text-center p-2 rounded-md focus:outline-none focus:border-blue-400" />
        </div>

        <div className="flex flex-col w-full space-y-2">
          <h3 className=""> Apellidos </h3>
          <input onChange={(e) => setUpdatedCredentials({ ...updatedCredentials, surname: e.target.value })} value={updatedCredentials.surname} disabled={!enableCredentialsMod} placeholder={surname && surname} type="text" className="w-full border-b border-blue-300 text-center p-2 rounded-md focus:outline-none focus:border-blue-400" />
        </div>

        <div className="flex flex-col w-full space-y-2">
          <h3 className=""> Teléfono </h3>
          <input onChange={(e) => setUpdatedCredentials({ ...updatedCredentials, telephone: e.target.value })} value={updatedCredentials.telephone} disabled={!enableCredentialsMod} placeholder={telephone || ''} type="text" className="w-full border-b border-blue-300 text-center p-2 rounded-md focus:outline-none focus:border-blue-400" />
        </div>
        <button
          type="button"
          className="w-1/3 max-w-lg mx-auto h-8 rounded-lg bg-red-100 font-body focus:outline-none"
          onClick={() => {
            if ((updatedCredentials.email !== '' && updatedCredentials.email.includes('@')) || updatedCredentials.name !== '' || updatedCredentials.surname !== '' || updatedCredentials.telephone !== '') {
              updateUserCrenditals();
            }
            setEnableCredentialsMod(!enableCredentialsMod);
          }}
        >
          { enableCredentialsMod ? 'Enviar' : 'Modificar' }
        </button>
        <div className="flex flex-col w-full space-y-4">
          <h3 className="pt-4"> Contraseña </h3>
          <input onChange={(e) => setUpdatedCredentials({ ...updatedCredentials, oldPassword: e.target.value })} value={updatedCredentials.oldPassword} disabled={!enablePassMod} placeholder="Contraseña actual" type="text" className="w-full border-b border-blue-300 text-center p-2 rounded-md focus:outline-none focus:border-blue-400" />
          <input onChange={(e) => setUpdatedCredentials({ ...updatedCredentials, newPassword: e.target.value })} value={updatedCredentials.newPassword} disabled={!enablePassMod} placeholder="Contraseña nueva" type="text" className="w-full border-b border-blue-300 text-center p-2 rounded-md focus:outline-none focus:border-blue-400" />
          <button
            type="button"
            className="w-1/3 max-w-lg mx-auto h-8 border-t border-b rounded-lg bg-red-100 boder-blue-100 font-body focus:outline-none"
            onClick={() => {
              updateUserPassword();
            }}
          >
            { enablePassMod ? 'Enviar' : 'Modificar' }
          </button>
        </div>

      </div>
      { error ? <span className="font-body text-red-300 text-xs pt-3"> La contraseña debe tener al menos 8 caracteres </span> : null }
      <div className="w-full flex flex-col smScreen:flex-row smScreen:mt-10 smScreen:px-4 smScreen:space-y-0 smScreen:space-x-2 pb-32 space-y-3 justify-center items-center mt-10">
        <button
          type="button"
          className="w-2/3 max-w-lg mx-auto rounded-md h-16 bg-blue-100 font-body"
          onClick={() => {
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('isRegistered');
            dispatchIncident({ type: 'resetIncidents' });
            navigate(ROUTES.login);
          }}
        >
          Cerrar Sesión
        </button>
      </div>
    </div>
  );
};

export default Registered;
