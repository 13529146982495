import React from 'react';
import { navigate } from '@reach/router';

import ROUTES from '../../../routes/routes';

const index = ({ item }) => (
  <div className="flex flex-col w-full bg-indigo-100 rounded-md shadow-lg mb-5 font-body" onClick={() => navigate(`${ROUTES.news}/${item.id}`)} role="button" onKeyDown={() => {}} tabIndex={0}>
    <div className="flex w-full px-2 pt-4 pb-2 justify-between items-center">
      <div className="flex border-b border-t border-blue-300 py-1 px-2 rounded-md">
        <span className="text-xs">
          {item.categoria}
        </span>
      </div>
      <div className="flex border-b border-t border-blue-300 py-1 px-2 rounded-md">
        <span className="text-xs">
          {item.date}
        </span>
      </div>
    </div>
    <div className="flex flex-col w-full justify-center items-center space-y-1">
      <div className="flex flex-col justify-center items-center mt-1">
        <span className="text-center text-sm font-bold">
          {item.titulo}
        </span>
      </div>
      <div className="flex justify-center items-center h-40 w-full">
        <img
          src={item.imagen}
          alt="logo"
          className="object-cover h-36 smScreen:h-36 w-64 sm:w-64 rounded-md"
        />
      </div>
    </div>
    <div className="pt-1 pb-2 px-4 text-center">
      <span className="text-center text-sm">
        {item.entradilla.substring(0, 50)}
        {' ... '}
        <span className="font-bold text-xs text-blue-500">Pulsa para leer más</span>
      </span>
    </div>
  </div>
);

export default index;
