import React, { useState } from 'react';

import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';

import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from '@reach/combobox';
import '@reach/combobox/styles.css';
import { PropTypes } from 'prop-types';
import { RINCONADA_LATITUDE, RINCONADA_LONGITUDE } from '../../../constants/config';

const Search = ({ panTo }) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => RINCONADA_LATITUDE, lng: () => RINCONADA_LONGITUDE },
      radius: 100 * 1000,
    },
  });

  const [loading, setLoading] = useState(false);

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address) => {
    setLoading(true);
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      panTo({ lat, lng });
      setLoading(false);
    } catch (error) {
      console.error('Error retrieving results ', error);
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center flex-col">
      <div className="h-10 flex justify-center items-center font-body mt-5 md:mb-12">
        <Combobox onSelect={handleSelect}>
          <ComboboxInput
            className="combobox-main-div"
            value={value}
            style={{ maxWidth: '85vw' }}
            onChange={handleInput}
            disabled={!ready}
            placeholder="Introduce una dirección"
          />
          <ComboboxPopover>
            <ComboboxList className="font-body space-y-2 text-blue-400">
              {status === 'OK'
                && data.map(({ id, description }) => (
                  <ComboboxOption key={id} value={description} />
                ))}
            </ComboboxList>
          </ComboboxPopover>
        </Combobox>
      </div>
      {loading && <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-8 w-8 mt-4" />}
    </div>
  );
};
Search.propTypes = {
  panTo: PropTypes.func.isRequired,
};

export default Search;
