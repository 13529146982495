import { RINCONADA_LATITUDE, RINCONADA_LONGITUDE } from './config';

const libraries = ['places'];
const mapContainerStyle = {
  marginTop: '1.5rem',
  maxWidth: '85vw',
  marginRight: 'auto',
  marginLeft: 'auto',
  width: '100vw',
  height: '35vh',
  borderRadius: '1.5rem',
  postion: 'relative',
};
const mapContainerStyleForIncidentDetail = {
  marginTop: '0.5rem',
  maxWidth: '90vw',
  marginRight: 'auto',
  marginLeft: 'auto',
  width: '100vw',
  height: '45vh',
  borderRadius: '1.5rem',
  postion: 'relative',
};
const options = {
  disableDefaultUI: true,
};
const center = {
  lat: RINCONADA_LATITUDE,
  lng: RINCONADA_LONGITUDE,
};

export {
  libraries, mapContainerStyle, options, center, mapContainerStyleForIncidentDetail,
};
