import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router';
import MainLayout from '../../components/MainLayout';
import Button from '../../components/Button/index';
import { DoRegistry } from '../../services/api/Auth';
import { useAlertDispatch } from '../../services/Contexts/Alert';
import ROUTES from '../../routes/routes';
import validateCredentials from './helpers';

const index = () => {
  const dispatchAlert = useAlertDispatch();
  const [registryCredentials, setRegistryCredentials] = useState({
    email: '',
    name: '',
    surname: '',
    telephone: '',
    address: '',
  });
  const [enableRegistry, setEnableRegistry] = useState(false);
  const [loading, setLoading] = useState(false);

  const resetCredentials = () => {
    setRegistryCredentials({
      email: '',
      name: '',
      surname: '',
      telephone: '',
      address: '',
    });
  };

  useEffect(() => {
    const makeRegistration = async () => {
      setLoading(true);
      const { status, reason } = validateCredentials({ values: registryCredentials });
      if (status) {
        const res = await DoRegistry(registryCredentials);
        if (res && res.ok) {
          resetCredentials();
          setLoading(false);
          navigate(ROUTES.signupSucces);
        } else {
          const error = await res.json();
          if (res.status === 409 && error.error === 'User already register') {
            dispatchAlert({
              type: 'enableAlert',
              payload: {
                alertText: 'Ya existe una cuenta con este email. Revise su correo electrónico para confirmar su cuenta o inténtelo de nuevo.',
                alertColor: 'bg-red-200',
              },
            });
            setLoading(false);
          } else {
            dispatchAlert({ type: 'enableStandardErrorAlert' });
            setLoading(false);
          }
        }
      } else {
        dispatchAlert({
          type: 'enableAlert',
          payload: {
            alertText: `Ha ocurrido un error. ${reason}`,
            alertColor: 'bg-red-200',
          },
        });
        setLoading(false);
      }
    };
    setEnableRegistry(false);
    if (enableRegistry) makeRegistration();
  }, [enableRegistry]);

  return (
    <MainLayout text="REGISTRO" showBottomNavBar={false}>
      <div className="h-screen max-w-md mx-auto w-full flex flex-col items-center sm:justify-center pt-40 standalone:pt-44 smScreen:pt-40">
        <div className="w-full max-w-3xl flex flex-col space-y-3 justify-center items-center px-8 font-body">

          <div className="flex justify-center items-center w-full space-y-2 bg-white mb-12">
            <h1 className="font-bold text-center text-lg"> Introduzca sus datos para proceder con el registro </h1>
          </div>

          <div className="flex flex-col w-full space-y-2 bg-white">
            <h3 className=""> Correo </h3>
            <input onChange={(e) => setRegistryCredentials({ ...registryCredentials, email: e.target.value.trim() })} value={registryCredentials.email} type="text" className="w-full border-b border-blue-300 text-center p-2 rounded-md focus:outline-none focus:border-blue-400" />
          </div>

          <div className="flex flex-col w-full space-y-2">
            <h3 className=""> Nombre </h3>
            <input onChange={(e) => setRegistryCredentials({ ...registryCredentials, name: e.target.value })} value={registryCredentials.name} type="text" className="w-full border-b border-blue-300 text-center p-2 rounded-md focus:outline-none focus:border-blue-400" />
          </div>

          <div className="flex flex-col w-full space-y-2">
            <h3 className=""> Apellidos </h3>
            <input onChange={(e) => setRegistryCredentials({ ...registryCredentials, surname: e.target.value })} value={registryCredentials.surname} type="text" className="w-full border-b border-blue-300 text-center p-2 rounded-md focus:outline-none focus:border-blue-400" />
          </div>

          <div className="flex flex-col w-full space-y-2">
            <h3 className=""> Teléfono </h3>
            <input onChange={(e) => setRegistryCredentials({ ...registryCredentials, telephone: e.target.value })} value={registryCredentials.telephone} type="text" className="w-full border-b border-blue-300 text-center p-2 rounded-md focus:outline-none focus:border-blue-400" />
          </div>

          <div className="w-full flex pt-10 pb-4">
            <Button
              className="bg-blue-200 focus:outline-none mr-3 hover:bg-blue-300"
              text="Atrás"
              onClick={() => navigate(-1)}
            />
            <Button
              className="bg-blue-300 focus:outline-none"
              text="Registrarse"
              onClick={() => setEnableRegistry(true)}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default index;
