import React from 'react';

const LoadIcon = () => (
  <svg className="w-4 h-4 animate-spin mr-4 text-white" viewBox="0 0 30 27" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.2" d="M27.5897 11.8401C27.2086 11.8401 26.843 11.9764 26.5735 12.219C26.3039 12.4616 26.1525 12.7906 26.1525 13.1337C26.1696 15.5564 25.2475 17.9088 23.5449 19.7855C21.8423 21.6622 19.4658 22.9458 16.8248 23.4153C14.1838 23.8847 11.4435 23.5106 9.07597 22.3574C6.70844 21.2042 4.86185 19.344 3.85423 17.0973C2.84661 14.8506 2.74104 12.358 3.55571 10.0487C4.37038 7.7395 6.05428 5.75823 8.3174 4.44615C10.5805 3.13407 13.2812 2.57332 15.9542 2.86048C18.6273 3.14764 21.1054 4.26472 22.9618 6.01935H19.5125C19.1313 6.01935 18.7657 6.15563 18.4962 6.39821C18.2267 6.64079 18.0752 6.9698 18.0752 7.31286C18.0752 7.65592 18.2267 7.98492 18.4962 8.2275C18.7657 8.47009 19.1313 8.60637 19.5125 8.60637H26.0232C26.4043 8.60637 26.7699 8.47009 27.0394 8.2275C27.309 7.98492 27.4604 7.65592 27.4604 7.31286V1.49206C27.4604 1.149 27.309 0.81999 27.0394 0.57741C26.7699 0.33483 26.4043 0.19855 26.0232 0.19855C25.642 0.19855 25.2764 0.33483 25.0069 0.57741C24.7373 0.81999 24.5859 1.149 24.5859 1.49206V3.78157C22.1924 1.72238 19.0824 0.470125 15.7814 0.236487C12.4805 0.00284897 9.19113 0.802159 6.46942 2.4993C3.74771 4.19645 1.76059 6.68732 0.843944 9.55087C-0.0726981 12.4144 0.137372 15.475 1.43864 18.2152C2.73992 20.9555 5.05257 23.2073 7.98567 24.5901C10.9188 25.9728 14.2924 26.4017 17.5363 25.8042C20.7802 25.2067 23.6954 23.6195 25.7891 21.3108C27.8827 19.0021 29.0265 16.1137 29.027 13.1337C29.027 12.7906 28.8756 12.4616 28.606 12.219C28.3365 11.9764 27.9709 11.8401 27.5897 11.8401Z" fill="black" />
  </svg>
);

export default LoadIcon;
export { LoadIcon };
