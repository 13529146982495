import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import getAgenda from '../api/publicServices';
import { weekDaysAgenda } from '../../screens/PublicServices/config';

const AgendaState = React.createContext();
const AgendaDispatch = React.createContext();

const AgendaReducer = (state, action) => {
  switch (action.type) {
    case 'requestAgenda':
      return {
        ...state,
        enableAgendaRequest: true,
        loading: true,
        agenda: [],
      };
    case 'agendaRequested':
      return {
        ...state,
        enableAgendaRequest: false,
        loading: false,
        error: false,
        agenda: [...state.agenda, action.payload.agenda],
      };
    case 'agendaRequestError':
      return {
        ...state,
        error: true,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const AgendaProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(AgendaReducer, {
    enableAgendaRequest: false,
    loading: false,
    error: false,
    total: 0,
    agenda: [],
  });

  useEffect(() => {
    const retrievingAgenda = async () => {
      weekDaysAgenda.map(async (date) => {
        const response = await getAgenda(date);
        if (response && response.status === 200) {
          await response.json().then((result) => {
            if (result && !result.error) {
              if (result.eventos.length > 0) {
                result.eventos.map((res) => dispatch({
                  type: 'agendaRequested',
                  payload: {
                    agenda: res,
                  },
                }));
              }
            } return null;
          });
        } else {
          dispatch({ type: 'agendaRequestError' });
        }
      });
    };
    if (state.enableAgendaRequest) retrievingAgenda();
  }, [state.enableAgendaRequest]);

  return (
    <AgendaState.Provider value={state}>
      <AgendaDispatch.Provider value={dispatch}>
        {children}
      </AgendaDispatch.Provider>
    </AgendaState.Provider>
  );
};

const useAgendaState = () => {
  const context = React.useContext(AgendaState);
  if (context === undefined) {
    throw new Error('Agenda context must be used within a provider');
  }
  return context;
};

const useAgendaDispatch = () => {
  const context = React.useContext(AgendaDispatch);
  if (context === undefined) {
    throw new Error('Agenda dispatch must be used within a provider');
  }
  return context;
};

AgendaProvider.propTypes = {
  children: PropTypes.element,
};

AgendaProvider.defaultProps = {
  children: {},
};

export { AgendaProvider, useAgendaState, useAgendaDispatch };
