const incidentsArrayToObject = (array, key) => {
  const initalValue = {};
  return array.reduce((obj, item) => ({
    ...obj,
    [item[key]]: {
      ...item,
      status: null,
    },
  }), initalValue);
};

export default incidentsArrayToObject;
