import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { uploadImages } from '../../../services/api/Minio';

const UploadPhotoButton = ({ incidentInfo, setIncidentInfo }) => {
  const photoRef = useRef();
  const [photoFile, setPhotoFile] = useState('');

  const photoUploadHandler = async (e) => {
    setPhotoFile(e.target.files[0]);
    const files = await uploadImages(e.target.files);
    if (files) {
      setIncidentInfo({
        ...incidentInfo,
        images: files.map((file) => new Array(file)),
      });
    }
  };

  return (
    <div>
      <input
        className="hidden"
        type="file"
        onChange={photoUploadHandler}
        accept="image/png, image/jpeg, image/jpg"
        ref={photoRef}
        multiple
      />
      <div className="flex justify-center items-center space-x-2 max-w-5xl mx-auto">
        <button
          onClick={() => {
            photoRef.current.click();
          }}
          type="button"
          className="mt-5 w-full h-10 font-body rounded-md bg-blue-100 focus:outline-none text-sm"
        >
          Subir fotos
        </button>
        <button
          type="button"
          className={`mt-5 w-10 h-10 rounded-md ${photoFile ? 'bg-green-300' : 'bg-red-300'} focus:outline-none disabled:bg-red-300`}
        >
          <></>
        </button>
      </div>
    </div>
  );
};

UploadPhotoButton.propTypes = {
  incidentInfo: PropTypes.objectOf(PropTypes.shape({
    address: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    code: PropTypes.number.isRequired,
    coords: {
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
    },
    creDate: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    executor: PropTypes.string.isRequired,
    family: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(PropTypes.shape(PropTypes.string)),
    modDate: PropTypes.number.isRequired,
    number: PropTypes.number.isRequired,
    orderType: PropTypes.string.isRequired,
    presenterEmail: PropTypes.string.isRequired,
    presenterName: PropTypes.string.isRequired,
    presenterSurname: PropTypes.string.isRequired,
    presenterTelephone: PropTypes.string.isRequired,
    printed: PropTypes.bool.isRequired,
    status: PropTypes.string.isRequired,
    street: PropTypes.string.isRequired,
    subfamily: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    validated: PropTypes.bool.isRequired,
    zipCode: PropTypes.number.isRequired,
    zone: PropTypes.string.isRequired,
  })).isRequired,
  setIncidentInfo: PropTypes.func.isRequired,
};

export default UploadPhotoButton;
