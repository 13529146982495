import moment from 'moment';
import 'moment/locale/es';

moment.locale('es');
const DAYS_TO_SUBTRACT = 5;

const weekDays = [];
for (let day = 0; day <= DAYS_TO_SUBTRACT; day += 1) {
  weekDays.push(day);
}
weekDays.map((day) => weekDays.push(moment().subtract(day, 'day').format('yyyy-MM-DD')));
weekDays.splice(0, DAYS_TO_SUBTRACT + 1);

const dateToShowInit = moment().subtract(DAYS_TO_SUBTRACT, 'day').format('dd DD/MM/yyyy');
const dateToShowFinal = moment().format('dd DD/MM/yyyy');

export { dateToShowInit, dateToShowFinal, weekDays };
