/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';

import MainLayout from '../../components/MainLayout';
import NewsCard from '../../components/Card/NewsCard';
import { useNewsDispatch, useNewsState } from '../../services/Contexts/News';

import { GlobeIcon } from '../../assets/icons/Globe';
import { dateToShowInit, dateToShowFinal } from './config';
import { WarningIcon } from '../../assets/icons/Warning';

const index = () => {
  const dispatchNews = useNewsDispatch();
  const {
    requestNewsState, news, loading, error,
  } = useNewsState();

  useEffect(() => {
    if (!requestNewsState) {
      dispatchNews({
        type: 'requestNews',
      });
    }
  }, [requestNewsState]);

  return (
    <MainLayout text="ÚLTIMAS NOTICIAS">
      <div className="standalone:pt-40 pt-36">
        <div className="flex flex-col justify-center items-center font-body mt-2">
          <h2 className="text-black text-lg">
            Noticias de la semana:
          </h2>
          <span className="text-gray-400 text-sm">
            {dateToShowInit}
            {' '}
            -
            {' '}
            {dateToShowFinal}
          </span>
        </div>
        <div className="flex justify-center items-center font-body mt-4 space-x-10">
          <span className="text-xs text-gray-400 rounded-md p-1">
            {' '}
            <span className="font-bold">Total:</span>
            {' '}
            <span>{news && news.length}</span>
            {' '}
          </span>
          <button
            onClick={() => dispatchNews({
              type: 'updateNews',
            })}
            type="button"
            className="border-b border-t border-blue-300 rounded-lg p-2 focus:outline-none text-sm text-gray-400"
          >
            Refrescar
          </button>
        </div>
        {error
          ? (
            <div className="h-12 w-full flex flex-col justify-center items-center font-body mt-16">
              <div className="mx-10 text-red-300 flex flex-col justify-center items-center space-y-2 text-center">
                <WarningIcon size={8} color="text-red-200" />
                <h1>Ha ocurrido algún error durante la petición al servidor de noticias.</h1>
              </div>
            </div>
          )
          : null}
        {loading
          ? <div className="flex justify-center items-center h-64 w-full"><GlobeIcon size={12} /></div>
          : (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-6 pt-5 pb-28 max-w-5xl mx-auto px-3">
              {news.sort((a, b) => new Date(b.date) - new Date(a.date)).map((i, id) => (
                <NewsCard key={id} item={i} />
              ))}
            </div>
          )}
      </div>
    </MainLayout>
  );
};

export default index;
