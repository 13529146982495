const ROUTES = {
  login: '/inicio',
  recoverPass: '/recuperarpassword',
  signup: '/registro',
  signupSucces: '/registro/completado',
  dashboard: '/menu',
  incidentList: '/incidencias',
  incidents: '/incidencias/crear',
  incidentDetail: '/incidencias/detalle',
  incidentsCreate: '/incidents/crear/detalle',
  tickets: '/tickets',
  publicServices: '/serviciosPublicos',
  headQuarters: '/headQuarters',
  publicServicesPharmacy: 'serviciosPublicos/farmacias-de-guardia',
  publicServicesBuses: '/serviciosPublicos/buses',
  publicServicesBusesConsorcio: '/serviciosPublicos/buses/consorcio',
  publicServicesAgenda: '/serviciosPublicos/agenda',
  publicServicesAgendaDetail: '/serviciosPublicos/agenda/detalle',
  publicServicesUrbanBuses: 'https://siu.ctas.ctan.es/es/horarios.php',
  publicServicesGeneralInformation: '/serviciosPublicos/informacion',
  publicServicesAppointment: '/serviciosPublicos/cita-previa',
  ticketsRinconada: 'https://entradas.larinconada.es',
  headQuartersRinconada: 'https://carpeta.larinconada.es',
  news: '/noticias',
  newsDetail: '/noticias/:id',
  profile: '/perfil',
};

export default ROUTES;
