import { useLocation } from '@reach/router';
import React from 'react';
import MainLayout from '../../../components/MainLayout';
import IncidentDetailElement from '../../IncidentManager/IncidentDetail/components/IncidentDetailElement';

const AgendaDetail = () => {
  const data = useLocation();
  const { agenda } = data.state;

  const createHTML = () => ({
    __html: agenda.cuerpo,
  });
  return (
    <MainLayout text="AGENDA">
      <div className="standalone:pt-40 pt-36 font-body">
        <div className="flex flex-col px-4 space-y-4">
          <span className="font-semibold text-center text-xl text-blue-500">{agenda.titulo}</span>
          <img alt="agenda_image" src={agenda.imagen} className="w-full rounded-md" />
          <IncidentDetailElement element={agenda.hora} text="Hora:" />
          <IncidentDetailElement element={agenda.direccion} text="Lugar:" />
          <IncidentDetailElement element={agenda.categoria} text="Categoría:" />
          <div className="flex border-b pt-4 rounded-md shadow-lg py-2 items-center justify-center">
            <span className="font-semibold text-blue-500"> Información </span>
          </div>
          <div dangerouslySetInnerHTML={createHTML()} className="pb-24 px-2" />
        </div>
      </div>
    </MainLayout>
  );
};
export default AgendaDetail;
