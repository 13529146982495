import React from 'react';
import { PropTypes } from 'prop-types';
import getStatusColor, { getStatusName } from '../../../screens/IncidentManager/IncidentList/utils';
import IncidentDetailElement from '../../../screens/IncidentManager/IncidentDetail/components/IncidentDetailElement';

const IncidentListCard = (props) => {
  const {
    code, state, description,
  } = props;
  return (
    <div className="flex flex-col w-full h-28 bg-indigo-100 items-center rounded-md shadow-lg">
      <div className="flex w-full justify-between items-center pt-3 px-5">
        <div className="border border-gray-300 rounded-md px-2 text-sm">
          <h1>
            {' '}
            <IncidentDetailElement element={code} text="Código:" />
            {' '}
          </h1>
        </div>
        <div className={`border rounded-md px-2 py-1 text-sm ${getStatusColor({ color: state })}`}>
          <h1>
            {' '}
            <IncidentDetailElement element={getStatusName({ name: state })} text="Estado:" />
            {' '}
          </h1>
        </div>
      </div>
      <div className="my-4 text-sm text-center overflow-y-auto px-1">
        <p>
          {' '}
          {description}
          {' '}
        </p>
      </div>
    </div>
  );
};

IncidentListCard.propTypes = {
  code: PropTypes.string,
  state: PropTypes.string,
  description: PropTypes.string,
};

IncidentListCard.defaultProps = {
  code: '',
  state: '',
  description: '',
};

export default IncidentListCard;
