import { ElectronicHeadQuartersIcon } from '../assets/icons/ElectronicHeadquarters';
import { NewsIcon } from '../assets/icons/News';
import { ParkIcon } from '../assets/icons/Park';
import { TicketIcon } from '../assets/icons/Ticket';
import { UserIcon } from '../assets/icons/User';
import { WarningIcon } from '../assets/icons/Warning';
import ROUTES from '../routes/routes';

const DashboardItems = [
  {
    name: 'Últimas noticias',
    url: ROUTES.news,
    icon: NewsIcon,
  },
  {
    name: 'Incidencias',
    url: ROUTES.incidentList,
    icon: WarningIcon,
  },
  {
    name: 'Eventos y entradas',
    url: ROUTES.ticketsRinconada,
    icon: TicketIcon,
  },
  {
    name: 'Sede electrónica',
    url: ROUTES.headQuartersRinconada,
    icon: ElectronicHeadQuartersIcon,
  },
  {
    name: 'Servicios públicos',
    url: ROUTES.publicServices,
    icon: ParkIcon,
  },
  {
    name: 'Mi perfil',
    url: ROUTES.profile,
    icon: UserIcon,
  },
];

export default DashboardItems;
