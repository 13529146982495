import React from 'react';
import { Router as ReachRouter, navigate } from '@reach/router';

import Login from '../screens/Login';
import RecoverPassword from '../screens/RecoverPassword';
import SignUp from '../screens/SignUp';
import SignUpSuccess from '../screens/SignUp/components/SignUpSuccess';
import Dashboard from '../screens/DashBoard';
import PublicServices from '../screens/PublicServices';
import PharmacyOpen from '../screens/PublicServices/components/PharmacyOpen';
import BusSchedule from '../screens/PublicServices/components/BusSchedule';
import BusScheduleConsorcio from '../screens/PublicServices/components/BusScheduleConsorcio';
import Agenda from '../screens/PublicServices/components/Agenda';
import AgendaDetail from '../screens/PublicServices/components/AgendaDetail';
import ElectronicHeadQuarters from '../screens/ElectronicHeadQuarters';
import LatestNews from '../screens/LatestNews';
import NewsCardDetail from '../components/Card/NewsCardDetail';
import TicketsManager from '../screens/TicketsManager';
import UserProfile from '../screens/UserProfile';
import GeneralInformation from '../screens/PublicServices/components/GeneralInformation';
import IncidentManager from '../screens/IncidentManager';
import IncidentManagerModal from '../components/GoogleMap/Components/IncidentManagerModal';
import IncidentList from '../screens/IncidentManager/IncidentList/index';
import IncidentDetail from '../screens/IncidentManager/IncidentDetail/index';
import ROUTES from './routes';

const Router = () => {
  const accessTokenFromLocal = localStorage.getItem('access_token');
  const refreshTokenFromLocal = localStorage.getItem('refresh_token');

  if (!accessTokenFromLocal && !refreshTokenFromLocal) navigate(ROUTES.dashboard);
  if (accessTokenFromLocal) localStorage.setItem('isRegistered', true);

  return (
    <ReachRouter>
      <Login path={ROUTES.login} default />
      <RecoverPassword path={ROUTES.recoverPass} />
      <SignUp path={ROUTES.signup} />
      <PublicServices path={ROUTES.publicServices} />
      <SignUpSuccess path={ROUTES.signupSucces} />
      <Dashboard path={ROUTES.dashboard} />
      <PharmacyOpen path={ROUTES.publicServicesPharmacy} />
      <BusSchedule path={ROUTES.publicServicesBuses} />
      <BusScheduleConsorcio path={ROUTES.publicServicesBusesConsorcio} />
      <GeneralInformation path={ROUTES.publicServicesGeneralInformation} />
      <Agenda path={ROUTES.publicServicesAgenda} />
      <AgendaDetail path={ROUTES.publicServicesAgendaDetail} />
      <UserProfile path={ROUTES.profile} />
      <ElectronicHeadQuarters path={ROUTES.headQuarters} />
      <LatestNews path={ROUTES.news} />
      <NewsCardDetail path={ROUTES.newsDetail} />
      <TicketsManager path={ROUTES.ticketsRinconada} />
      <IncidentManager path={ROUTES.incidents} />
      <IncidentList path={ROUTES.incidentList} />
      <IncidentDetail path={ROUTES.incidentDetail} />
      <IncidentManagerModal path={ROUTES.incidentsCreate} />
    </ReachRouter>
  );
};

export default Router;
