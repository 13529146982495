import React from 'react';
import PropTypes from 'prop-types';
import TopNavBar from './NavBar/TopNavBar';
import BottomNavBar from './NavBar/BottomNavBar';

const MainLayout = ({ children, text, showBottomNavBar }) => (
  <div
    className="h-screen w-full"
    style={{ WebkitTapHighlightColor: 'transparent' }}
  >
    <TopNavBar text={text} />
    { children }
    {showBottomNavBar ? <BottomNavBar /> : null}
  </div>
);

MainLayout.propTypes = {
  children: PropTypes.element.isRequired,
  text: PropTypes.string,
  showBottomNavBar: PropTypes.bool,
};

MainLayout.defaultProps = {
  text: '',
  showBottomNavBar: true,
};

export default MainLayout;
