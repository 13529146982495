import { useCallback } from 'react';
import { DoMe } from '../api/Auth';
import {
  getIncidents as getIncidentsThrougAPI,
  getIncidentStatus as getIncidentStatusThrougAPI,
} from '../api/Incidents';
import { useAuthState, useAuthDispatch } from '../Contexts/Auth';
import { useIncidentDispatch, useIncidentState } from '../Contexts/Incident';
import arrayToObject from '../../helpers/incidentsArrayToObject';

const useIncidents = () => {
  const dispatchIncident = useIncidentDispatch();
  const {
    count,
    page,
    total,
    incidents,
  } = useIncidentState();
  const { id } = useAuthState();
  const dispatchAuth = useAuthDispatch();

  const setLoadingTo = (loadingNewValue) => dispatchIncident({
    type: 'setLoading',
    payload: loadingNewValue,
  });

  const setError = (error) => dispatchIncident({
    type: 'setError',
    payload: error,
  });

  const resetIncidentsContext = () => dispatchIncident({
    type: 'resetIncidents',
  });

  const askForNewPage = () => dispatchIncident({
    type: 'newPage',
  });

  const refreshAuthIfNeeded = async () => {
    try {
      if (!id) {
        const resMe = await DoMe();
        const me = await resMe.json();
        dispatchAuth({
          type: 'login',
          payload: {
            id: me.user.id,
          },
        });
        return me.user.id;
      }
      return id;
    } catch (error) {
      setError(error);
      return error;
    }
  };

  const getIncidents = useCallback(async () => {
    try {
      if (total === undefined || Object.values(incidents).length < total) {
        setLoadingTo(true);
        const userId = await refreshAuthIfNeeded();
        const res = await getIncidentsThrougAPI(userId, count, page);
        const data = await res.json();
        const incidentsObject = arrayToObject(data.historicals, 'entityId');
        await Promise.all(Object.values(incidentsObject).map(async (incident) => {
          const resStatus = await getIncidentStatusThrougAPI(incident.entityId);
          const dataStatus = await resStatus.json();
          incidentsObject[incident.entityId].status = dataStatus.historicals[0].status;
        }));
        dispatchIncident({
          type: 'saveIncidents',
          payload: {
            count: data.count,
            total: data.total,
            incidents: incidentsObject,
          },
        });
        setLoadingTo(false);
      }
    } catch (error) {
      setError(error);
    }
  }, [page]);

  return {
    getIncidents,
    resetIncidentsContext,
    askForNewPage,
  };
};

export default useIncidents;
