import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';

const DashboardCard = ({
  item,
}) => (
  <div className="flex flex-col h-34" onClick={() => navigate(`${item.url}`)} role="button" onKeyDown={() => {}} tabIndex={0}>
    <div className="flex flex-col h-full justify-center items-center shadow-xl py-5 px-3 rounded-lg space-y-3 mt-1 bg-blue-100">
      <item.icon />
      <div className="flex space-x-2">
        <button type="button" className="w-full p-3 bg-transparent text-md rounded-lg focus:outline-none font-body font-medium">{item.name}</button>
      </div>
    </div>
  </div>
);

DashboardCard.propTypes = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
    icon: PropTypes.JSXElement,
  }),
).isRequired;

export default DashboardCard;
