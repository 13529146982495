import React from 'react';
import MainLayout from '../../components/MainLayout';
import Registered from './Registered';
import UnRegistered from './UnRegistered';

const index = () => {
  const isRegistered = localStorage.getItem('isRegistered') === 'true';
  return (
    <MainLayout text="AJUSTES">
      {isRegistered ? <Registered /> : <UnRegistered /> }
    </MainLayout>
  );
};

export default index;
