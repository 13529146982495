import React from 'react';
import MainLayout from '../../../components/MainLayout';

const Agenda = () => (
  <MainLayout text="FARMACIAS DE GUARDIA">
    <div className="standalone:pt-40 pt-36 max-w-sm mx-auto">
      <div className="flex flex-col justify-center items-center font-body mt-2 mb-44">
        <iframe title="pharmacys" src="https://www.larinconada.es/lib/php/farmacias-de-guardia.php" style={{ height: '65vh', width: '100%' }} />
      </div>
    </div>
  </MainLayout>
);

export default Agenda;
