const ObtainAddress = (results) => {
  let street;
  let city;
  let zone;

  const len = (results.results[0].address_components).length;
  const number = results.results[0].address_components[0].types[0] === 'street_number' ? results.results[0].address_components[0].long_name : '';
  const zipCode = results.results[0].address_components[len - 1].types[0] === 'postal_code' ? results.results[0].address_components[len - 1].long_name : '';

  results.results[0].address_components.map((address) => {
    if (address.types[0] === 'route') {
      street = address.long_name;
    } if (address.types[0] === 'locality') {
      city = address.long_name;
    } else { return null; }
    return null;
  });

  if (city === 'San José de la Rinconada') zone = 'SLR';
  else if (city === 'La Rinconada') zone = 'LR';
  else { zone = 'OTR'; }

  return {
    street, city, zone, number, zipCode,
  };
};

export default ObtainAddress;
