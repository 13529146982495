import moment from 'moment';
import 'moment/locale/es';

moment.locale('es');
const DAYS_TO_ADD = 30;

const weekDaysAgenda = [];
for (let day = 0; day <= DAYS_TO_ADD; day += 1) {
  weekDaysAgenda.push(day);
}
weekDaysAgenda.map((day) => weekDaysAgenda.push(moment().add(day, 'day').format('yyyy-MM-DD')));
weekDaysAgenda.splice(0, DAYS_TO_ADD + 2);

const dateToShowInit = moment().subtract(DAYS_TO_ADD, 'day').format('dd DD/MM/yyyy');
const dateToShowFinal = moment().format('dd DD/MM/yyyy');

export {
  dateToShowInit, dateToShowFinal, weekDaysAgenda, DAYS_TO_ADD,
};
