import React, { useState, useEffect } from 'react';
import {
  GoogleMap,
  useLoadScript,
  Marker,
} from '@react-google-maps/api';

import { navigate } from '@reach/router';
import Search from '../../components/GoogleMap/Components/Search';
import CreateIncidentButton from '../../components/GoogleMap/Components/CreateIncidentButton';

import { GOOGLE_MAP_API_KEY } from '../../constants/config';
import {
  libraries, mapContainerStyle, options, center,
} from '../../constants/mapConfig';
import MainLayout from '../../components/MainLayout';
import ROUTES from '../../routes/routes';
import { useAlertDispatch } from '../../services/Contexts/Alert';

const App = () => {
  const isPrivate = localStorage.getItem('isRegistered') === 'true';
  const isLogged = localStorage.getItem('access_token') && localStorage.getItem('access_token');
  const dispatchAlert = useAlertDispatch();
  useEffect(() => {
    if (!isPrivate || !isLogged) {
      navigate(ROUTES.dashboard);
      dispatchAlert({
        type: 'enableAlert',
        payload: {
          alertText: 'Debes estar registrado para acceder a este contenido. Si ya lo estaba cierre sesión e inicie de nuevo por favor.',
          alertColor: 'bg-blue-200',
        },
      });
    }
  }, []);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
    libraries,
  });
  const [markers, setMarkers] = useState([]);

  const onMapClick = React.useCallback((e) => {
    setMarkers(() => [
      {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
        time: new Date(),
      },
    ]);
  }, []);

  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  const panTo = React.useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(18);
    setMarkers(() => [
      {
        lat,
        lng,
        time: new Date(),
      },
    ]);
  }, []);

  if (loadError) return 'Error';
  if (!isLoaded) return null;

  return (

    <MainLayout text="CREAR INCIDENCIA">
      <div className="h-screen w-full flex flex-col items-center sm:justify-center pt-36 standalone:pt-40 smScreen:pt-32">
        <div className="w-full max-w-3xl flex flex-col space-y-3 justify-center items-center px-8 font-body">
          <Search panTo={panTo} />
          <div>
            <div className="md:flex items-center justify-center mt-3 md:mt-12 hidden space-x-3">
              <CreateIncidentButton markers={markers} panTo={panTo} />
            </div>
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              zoom={12}
              center={center}
              options={options}
              onClick={onMapClick}
              onLoad={onMapLoad}
            >
              {markers.map((marker) => (
                <Marker
                  key={`${marker.lat}-${marker.lng}`}
                  position={{ lat: marker.lat, lng: marker.lng }}
                  icon={{
                    url: '/location.svg',
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(15, 15),
                    scaledSize: new window.google.maps.Size(30, 30),
                  }}
                />
              ))}
            </GoogleMap>
            <div className="grid grid-cols-1 smScreen:pl-5 smScreen:ml-3 smScreen:pb-32">
              <div className="flex items-center justify-center md:mt-16 mt-3 space-x-3 md:hidden">
                <CreateIncidentButton markers={markers} panTo={panTo} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default App;
