import React, { useEffect } from 'react';
import { navigate } from '@reach/router';
import { useNewsState } from '../../../services/Contexts/News';

import ROUTES from '../../../routes/routes';
import MainLayout from '../../MainLayout';

const index = (props) => {
  const { news } = useNewsState();
  const newsDetail = news.filter((detail) => detail.id === props.id);
  const DATA = 0;

  if (!newsDetail[DATA] && navigate(ROUTES.dashboard));

  const createHTML = () => ({
    __html: newsDetail[DATA].cuerpo,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MainLayout text="ÚLTIMAS NOTICIAS">
      <div className="standalone:pt-28 pt-24">
        <div className="flex flex-col justify-center items-center font-body mt-2">

          <div className="h-screen w-full p-5 max-w-3xl mx-auto font-body pb-36 flex flex-col">
            <h1 className="text-center mt-7 mb-4 font-bold text-lg">
              {newsDetail[DATA] && newsDetail[DATA].titulo}
            </h1>
            <h1 className="text-center text-md mb-4">
              {newsDetail[DATA] && newsDetail[DATA].entradilla}
            </h1>
            <p className="text-xs text-gray-400 mb-4">
              Categoria:
              <span className="ml-1">
                {newsDetail[DATA] && newsDetail[DATA].categoria}
              </span>
            </p>
            <img src={newsDetail[DATA] && newsDetail[DATA].imagen} alt="newsDetail" className="rounded-lg shadow-lg mb-8" />
            <div dangerouslySetInnerHTML={newsDetail[DATA] && createHTML()} className="pb-24" />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default index;
