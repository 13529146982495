/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { weekDays } from '../../screens/LatestNews/config';

const NewsState = React.createContext();
const NewsDispatch = React.createContext();

const NewsReducer = (state, action) => {
  switch (action.type) {
    case 'requestNews':
      return {
        ...state,
        requestNewsState: true,
        loading: true,
        news: [],
        categories: [],
        total: 0,
      };
    case 'newsRequested':
      return {
        ...state,
        loading: false,
        error: false,
        news: [...state.news, action.payload.news],
        categories: [...state.categories, action.payload.categories],
        total: state.total + action.payload.total,
      };
    case 'updateNews':
      return {
        ...state,
        requestNewsState: false,
      };
    case 'newsRequestError':
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const NewsProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(NewsReducer, {
    requestNewsState: false,
    loading: false,
    error: false,
    total: 0,
    news: [],
    categories: [],
  });

  useEffect(() => {
    const retrievingNews = async () => {
      weekDays.map(async (date) => {
        const response = await fetch(`https://www.larinconada.es/api/noticias?token=ADD61523AAEFDD18822AA00&fecha=${date}`, { headers: { Accept: '*/*' } }).catch((e) => console(e));
        if (response && response.status === 200) {
          await response.json().then((result) => {
            if (result && !result.error) {
              result.noticias.map((n) => {
                n.date = date;
                return dispatch({
                  type: 'newsRequested',
                  payload: {
                    total: result.noticias.length,
                    news: n,
                    categories: n.categoria,
                  },
                });
              });
            }
          });
        } else {
          dispatch({ type: 'newsRequestError' });
        }
      });
    };
    if (state.requestNewsState) retrievingNews();
  }, [state.requestNewsState]);

  return (
    <NewsState.Provider value={state}>
      <NewsDispatch.Provider value={dispatch}>
        {children}
      </NewsDispatch.Provider>
    </NewsState.Provider>
  );
};

const useNewsState = () => {
  const context = React.useContext(NewsState);
  if (context === undefined) {
    throw new Error('News context must be used within a provider');
  }
  return context;
};

const useNewsDispatch = () => {
  const context = React.useContext(NewsDispatch);
  if (context === undefined) {
    throw new Error('News dispatch must be used within a provider');
  }
  return context;
};

NewsProvider.propTypes = {
  children: PropTypes.element,
};

NewsProvider.defaultProps = {
  children: {},
};

export { NewsProvider, useNewsState, useNewsDispatch };
