import React from 'react';
import './App.css';
import Router from './routes/index';

import { AuthProvider } from './services/Contexts/Auth';
import { AlertProvider } from './services/Contexts/Alert';
import { LocationProvider } from './services/Contexts/Location';
import { NewsProvider } from './services/Contexts/News';
import { IncidentProvider } from './services/Contexts/Incident';
import { AgendaProvider } from './services/Contexts/Agenda';
import AlertSystem from './components/AlertSystem';

const App = () => (
  <AuthProvider>
    <LocationProvider>
      <AlertProvider>
        <NewsProvider>
          <AgendaProvider>
            <IncidentProvider>
              <>
                <AlertSystem />
                <Router />
              </>
            </IncidentProvider>
          </AgendaProvider>
        </NewsProvider>
      </AlertProvider>
    </LocationProvider>
  </AuthProvider>
);

export default App;
