import React from 'react';
import PropTypes from 'prop-types';

const AlertState = React.createContext();
const AlertDispatch = React.createContext();

const AlertReducer = (state, action) => {
  switch (action.type) {
    case 'enableAlert':
      return {
        ...state,
        alertStatus: true,
        alertText: action.payload.alertText,
        alertColor: action.payload.alertColor,
      };
    case 'enableStandardErrorAlert':
      return {
        ...state,
        alertStatus: true,
        alertText: 'Ha ocurrido algún error. Inténtelo de nuevo.',
        alertColor: 'bg-red-200',
      };
    case 'disableAlert':
      return {
        ...state,
        alertStatus: false,
        alertText: '',
        alertColor: '',
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const AlertProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(AlertReducer, {
    alertStatus: false,
    alertText: '',
    alertColor: '',
  });
  React.useEffect(() => {
    if (state.alertStatus) {
      setTimeout(() => {
        dispatch({
          type: 'disableAlert',
        });
      }, 5000);
    }
  }, [state.alertStatus]);
  return (
    <AlertState.Provider value={state}>
      <AlertDispatch.Provider value={dispatch}>
        {children}
      </AlertDispatch.Provider>
    </AlertState.Provider>
  );
};

const useAlertState = () => {
  const context = React.useContext(AlertState);
  if (context === undefined) {
    throw new Error('Alert context must be used within a provider');
  }
  return context;
};

const useAlertDispatch = () => {
  const context = React.useContext(AlertDispatch);
  if (context === undefined) {
    throw new Error('Alert dispatch must be used within a provider');
  }
  return context;
};

AlertProvider.propTypes = {
  children: PropTypes.element,
};

AlertProvider.defaultProps = {
  children: {},
};

export { AlertProvider, useAlertState, useAlertDispatch };
