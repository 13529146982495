import React from 'react';
import PropTypes from 'prop-types';

const FormInput = ({
  type,
  placeholder,
  onChange,
}) => (
  <input
    type={type}
    className="w-full border-blue-300 bg-blue-100 focus:border-blue-400 border-b-2 p-3 rounded outline-none font-body"
    placeholder={placeholder}
    onChange={onChange}
  />
);

FormInput.propTypes = {
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FormInput;
