const validateCredentials = ({ values }) => {
  const empty = Object.entries(values).map(([key, value]) => (value.length === 0 && key !== 'address'));
  if (empty.includes(true)) return { status: false, reason: 'Hay algún campo vacío.' };
  if (/\d/.test(values.name) || /\d/.test(values.surname)) return { status: false, reason: 'Nombre o Apellidos inválidos.' };
  if (!values.email.includes('@') || !values.email.includes('.')) return { status: false, reason: 'Email inválido.' };
  if (values.telephone.length !== 9) return { status: false, reason: 'Teléfono inválido.' };
  if (!/^\d+$/.test(values.telephone)) return { status: false, reason: 'Teléfono inválido.' };
  return { status: true, reason: '' };
};

export default validateCredentials;
