import { NewsIcon } from '../assets/icons/News';
import { BusIcon } from '../assets/icons/Bus';
import { HomeBus } from '../assets/icons/HomeBus';
import { Appoinment } from '../assets/icons/Appoinment';
import { PharmacyIcon } from '../assets/icons/Pharmacy';
import ROUTES from '../routes/routes';

const PublicServicesItems = [
  {
    name: 'Agenda de eventos',
    url: ROUTES.publicServicesAgenda,
    icon: NewsIcon,
  },
  {
    name: 'Farmacias de guardia',
    url: ROUTES.publicServicesPharmacy,
    icon: PharmacyIcon,
  },
  {
    name: 'Horarios de autobuses urbanos',
    url: ROUTES.publicServicesBuses,
    icon: BusIcon,
  },
  {
    name: 'Horarios de autobuses consorcio',
    url: ROUTES.publicServicesBusesConsorcio,
    icon: BusIcon,
  },
  {
    name: 'Teléfonos e información de interés',
    url: ROUTES.publicServicesGeneralInformation,
    icon: HomeBus,
  },
  {
    name: 'Cita previa',
    url: 'https://larinconada.sededigital.org/Default.aspx?key=ywhv8ARPM/nr0PlcW+YkqLprLv+6/Pa3OpSA/HCm8C4CqZJwKayKx/OmST1Rc1PVfsywFeX3xSXmERrplmfh+Q==',
    icon: Appoinment,
  },
];

export default PublicServicesItems;
