import React from 'react';
import PropTypes from 'prop-types';
import INCIDENT_REQUEST_SIZE from '../../helpers/constants';

const IncidentState = React.createContext();
const IncidentDispatch = React.createContext();

const initialState = {
  count: INCIDENT_REQUEST_SIZE,
  page: 1,
  total: undefined,
  loading: false,
  incidents: {},
  status: {},
  errors: [],
};

const IncidentReducer = (state, action) => {
  switch (action.type) {
    case 'setLoading':
      return {
        ...state,
        loading: action.payload,
      };
    case 'newPage':
      return {
        ...state,
        page: state.page + 1,
      };
    case 'setError':
      return {
        ...state,
        errors: action.payload,
      };
    case 'saveIncidents':
      return {
        ...state,
        count: action.payload.count,
        total: action.payload.total,
        incidents: {
          ...state.incidents,
          ...action.payload.incidents,
        },
      };
    case 'updateStatus':
      return {
        ...state,
        status: action.payload.status,
      };
    case 'resetIncidents':
      return initialState;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const IncidentProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(IncidentReducer, initialState);
  return (
    <IncidentState.Provider value={state}>
      <IncidentDispatch.Provider value={dispatch}>
        {children}
      </IncidentDispatch.Provider>
    </IncidentState.Provider>
  );
};

const useIncidentState = () => {
  const context = React.useContext(IncidentState);
  if (context === undefined) {
    throw new Error('Alert context must be used within a provider');
  }
  return context;
};

const useIncidentDispatch = () => {
  const context = React.useContext(IncidentDispatch);
  if (context === undefined) {
    throw new Error('Alert dispatch must be used within a provider');
  }
  return context;
};

IncidentProvider.propTypes = {
  children: PropTypes.element,
};

IncidentProvider.defaultProps = {
  children: {},
};

export { IncidentProvider, useIncidentState, useIncidentDispatch };
