import React from 'react';
import MainLayout from '../../components/MainLayout';
import PublicServicesItems from '../../constants/publicServicesItems';
import DashboardCard from '../../components/Card/DashboardCard/index';

const index = () => (
  <MainLayout text="SERVICIOS PÚBLICOS">
    <div className="h-screen p-5 w-screen max-w-4xl mx-auto flex flex-col pt-40 standalone:pt-44 items-center smScreen:mt-2 sm:mt-8" style={{ WebkitTapHighlightColor: 'transparent' }}>
      <div className="container mx-auto smScreen:mt-5 mt-2 smScreen:pb-5 pb-2">
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 gap-5 mb-24">
          {PublicServicesItems.map((item) => (
            <DashboardCard item={item} key={item.name} />
          ))}
        </div>
      </div>
    </div>
  </MainLayout>
);

export default index;
