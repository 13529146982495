import React, { useEffect, useRef, useCallback } from 'react';
import { useLocation, navigate } from '@reach/router';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import MainLayout from '../../../components/MainLayout';
import {
  libraries, mapContainerStyleForIncidentDetail, options,
} from '../../../constants/mapConfig';
import { GOOGLE_MAP_API_KEY } from '../../../constants/config';
import IncidentDetailElement from './components/IncidentDetailElement';
import { getStatusName } from '../IncidentList/utils';
import { useAlertDispatch } from '../../../services/Contexts/Alert';
import ROUTES from '../../../routes/routes';

const index = () => {
  const isPrivate = localStorage.getItem('isRegistered') === 'true';
  const isLogged = localStorage.getItem('access_token') && localStorage.getItem('access_token');
  const dispatchAlert = useAlertDispatch();
  useEffect(() => {
    if (!isPrivate || !isLogged) {
      navigate(ROUTES.dashboard);
      dispatchAlert({
        type: 'enableAlert',
        payload: {
          alertText: 'Debes estar registrado para acceder a este contenido. Si ya lo estaba cierre sesión e inicie de nuevo por favor.',
          alertColor: 'bg-blue-200',
        },
      });
    }
  }, []);
  const data = useLocation();
  const { incident, status } = data.state;
  const lat = +incident.coords.split(':')[1].split(',')[0];
  const lng = +incident.coords.split(':')[2].split('}')[0];
  const date = new Date(+incident.creDate).toLocaleString();
  const center = {
    lat,
    lng,
  };
  const mapRef = useRef();
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
    libraries,
  });

  if (loadError) return 'Error';
  if (!isLoaded) return null;
  return (
    <MainLayout text="INCIDENCIAS">
      <div className="w-full pt-36 standalone:pt-40 sm:pt-44 font-body">
        <div className="flex flex-col space-y-3 px-5">
          <h1 className="font-bold sm:text-center"> Detalles de la incidencia: </h1>
          <IncidentDetailElement element={date.toString()} text="Fecha de creación:" />
          <IncidentDetailElement element={incident.address} text="Dirección: " />
          <IncidentDetailElement element={incident.code} text="Código:" />
          <IncidentDetailElement element={getStatusName({ name: status })} text="Estado:" />
          <IncidentDetailElement element={incident.description} text="Descripción:" />
          <div className="pb-24">
            <GoogleMap
              mapContainerStyle={mapContainerStyleForIncidentDetail}
              zoom={12}
              center={center}
              options={options}
              onLoad={onMapLoad}
            >
              <Marker
                key={`${lat}-${lng}`}
                position={{ lat, lng }}
                icon={{
                  url: '/location.svg',
                  origin: new window.google.maps.Point(0, 0),
                  anchor: new window.google.maps.Point(15, 15),
                  scaledSize: new window.google.maps.Size(30, 30),
                }}
              />
            </GoogleMap>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default index;
