/* eslint-disable no-async-promise-executor */
import { uuid } from 'uuidv4';
import { SERVER_URL } from '../../constants/config';

const uploadImages = async (files) => new Promise(async (resolve, reject) => {
  const promiseArray = [];
  Object.values(files).forEach((file) => {
    const fileName = `${uuid()}${file.name}`;
    return promiseArray.push(
      new Promise(async (resolveInside, rejectInside) => {
        try {
          const res = await fetch(`${SERVER_URL}/presign/put?name=${fileName}`, {
            method: 'GET',
            headers: {
              'fiware-service': 'rinconada',
              'fiware-servicepath': '/',
              'Content-Type': 'application/json',
              'X-Auth-Token': localStorage.getItem('access_token'),
            },
          });
          const putUrl = await res.text();
          const uploadAction = await fetch(putUrl, {
            method: 'PUT',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'image/jpg',
            },
            body: file,
          });
          const newLocal = 'Cannot upload';
          if (uploadAction.status === 200) resolveInside(fileName);
          else rejectInside(newLocal);
        } catch (error) {
          const newLocal_1 = 'Cannot upload';
          reject(newLocal_1);
        }
      }),
    );
  });
  const fileNames = await Promise.all(promiseArray).catch((e) => reject(new Error(e)));
  resolve(fileNames);
});

export default uploadImages;
export { uploadImages };
