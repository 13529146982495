const getStatusColor = ({ color }) => {
  switch (color) {
    case 'pending': {
      return 'bg-blue-200';
    }
    case 'open': {
      return 'bg-green-200';
    }
    case 'close': {
      return 'bg-gray-300';
    }
    case 'rejected': {
      return 'bg-red-200';
    }
    case 'irrelevant': {
      return 'bg-yellow-200';
    }
    default:
      return 'bg-white';
  }
};

const getStatusName = ({ name }) => {
  switch (name) {
    case 'pending': {
      return 'Pendiente';
    }
    case 'open': {
      return 'Abierta';
    }
    case 'close': {
      return 'Cerrada';
    }
    case 'rejected': {
      return 'Rechazada';
    }
    case 'irrelevant': {
      return 'Irrelevante';
    }
    default:
      return 'Cargando...';
  }
};

export default getStatusColor;
export { getStatusName };
