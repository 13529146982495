import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router';
import Button from '../../../components/Button/index';
import ROUTES from '../../../routes/routes';
import CouncilLogo from '../../../assets/images/simple-logo-rinconada.png';

const SignUpSuccess = () => {
  const [enableButton, setEnableButton] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setEnableButton(false);
    }, 2000);
  }, []);
  return (
    <div className="h-screen w-full max-w-sm mx-auto">
      <div className="h-full flex flex-col justify-center items-center px-10 space-y-12">
        <img src={CouncilLogo} alt="logo" className="object-contain h-36 w-full" />
        <p className="text-center text-lg text-bold">
          {' '}
          Su cuenta se ha creado
          {' '}
          <span className="text-blue-300">correctamente</span>
          ,
          revise su correo electrónico para confirmar su cuenta y poder acceder a la aplicación.
          {' '}
        </p>
        <Button
          className={`${enableButton ? 'bg-blue-200' : 'bg-blue-300'} focus:outline-none`}
          onClick={() => navigate(ROUTES.login)}
          text="IR AL INICIO"
          disabled={enableButton}
        />
      </div>
    </div>
  );
};

export default SignUpSuccess;
