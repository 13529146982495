import React from 'react';
import moment from 'moment';
import { Link } from '@reach/router';
import IncidentDetailElement from '../../../screens/IncidentManager/IncidentDetail/components/IncidentDetailElement';
import ROUTES from '../../../routes/routes';

const index = ({ agenda }) => (
  <div
    className="w-full"
    role="button"
    onKeyDown={() => null}
    tabIndex={0}
  >
    <Link
      to={ROUTES.publicServicesAgendaDetail}
      state={{ agenda }}
    >
      <div className="w-full px-4 mb-3">
        <div className="border border-gray-200 rounded-lg pb-2 shadow-xl">
          <img alt="agenda_image" src={agenda.imagen} className="w-full rounded-md" />
          <div className="flex justify-center items-center pt-2 px-3 space-x-5">
            <div className="flex flex-col justify-center items-center bg-indigo-100 p-2 rounded-md shadow-lg">
              {agenda && agenda.fecha && agenda.fecha.split('-').map((d, i) => (
                <div className="px-3">
                  <span className={`${i === 2 ? 'text-3xl font-semibold' : 'text-md'}`}>{i === 1 ? moment.months(d - 1) : d}</span>
                  {' '}
                </div>
              ))}
            </div>
            <span className="font-semibold text-center text-xl text-blue-500">{agenda.titulo}</span>
          </div>
          <div className="flex flex-col pt-3 px-5 space-y-1 pb-2">
            <IncidentDetailElement element={agenda.hora} text="Hora:" />
            <IncidentDetailElement element={agenda.direccion} text="Lugar:" />
            <IncidentDetailElement element={agenda.categoria} text="Categoría:" />
          </div>
        </div>
      </div>
    </Link>
  </div>
);

export default index;
