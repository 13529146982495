import React, {
  useEffect, useRef, useCallback,
} from 'react';
import { Link, navigate } from '@reach/router';
import MainLayout from '../../../components/MainLayout';
import { useIncidentState } from '../../../services/Contexts/Incident';
import IncidentListCard from '../../../components/Card/IncidentListCard/index';
import ROUTES from '../../../routes/routes';
import IncidentDetailElement from '../IncidentDetail/components/IncidentDetailElement';
import useIncidents from '../../../services/Hooks/Incidents';
import { useAlertDispatch } from '../../../services/Contexts/Alert';

const IncidentList = () => {
  const isPrivate = localStorage.getItem('isRegistered') === 'true';
  const isLogged = localStorage.getItem('access_token') && localStorage.getItem('access_token');
  const dispatchAlert = useAlertDispatch();
  useEffect(() => {
    if (!isPrivate || !isLogged) {
      navigate(ROUTES.dashboard);
      dispatchAlert({
        type: 'enableAlert',
        payload: {
          alertText: 'Debes estar registrado para acceder a este contenido. Si ya lo estaba cierre sesión e inicie de nuevo por favor.',
          alertColor: 'bg-blue-200',
        },
      });
    }
  }, []);
  const {
    incidents, total, loading,
  } = useIncidentState();
  const {
    getIncidents,
    resetIncidentsContext,
    askForNewPage,
  } = useIncidents();

  useEffect(() => {
    getIncidents();
  }, [getIncidents]);

  const observer = useRef();
  const lastIncidentElementRef = useCallback((node) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    const hasMore = Object.values(incidents).length !== total;
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        askForNewPage();
      }
    });
    if (node) observer.current.observe(node);
  }, [loading]);

  return (
    <MainLayout text="INCIDENCIAS">
      <div className="h-full w-full flex flex-col items-center sm:justify-center pt-36 standalone:pt-40 font-body sm:grid">
        <div className="mb-4 flex space-x-7">
          { incidents && <IncidentDetailElement element={total} text="Total:" />}
          <button
            onClick={() => {
              resetIncidentsContext();
              getIncidents();
            }}
            type="button"
            className="border-b border-t border-blue-300 rounded-lg p-2 focus:outline-none text-sm text-gray-400"
          >
            Refrescar
          </button>
        </div>
        <div className="w-full max-w-3xl flex flex-col space-y-3 justify-center items-center px-5 font-body pb-48">
          {incidents && Object.values(incidents)
            .sort((a, b) => new Date(b.creDate * 1000) - new Date(a.creDate * 1000))
            .map((incident, index) => (
              <div
                className="w-full"
                key={incident.id}
                ref={Object.values(incidents).length === index + 1 ? lastIncidentElementRef : null}
              >
                <Link
                  to={ROUTES.incidentDetail}
                  state={{ incident, status: incident.status }}
                  // onClick={() => dispatchIncident({
                  //   type: 'updateStatus',
                  //   payload: {
                  //     status: updatedIncidentStatus,
                  //   },
                  // })}
                >
                  <IncidentListCard
                    code={incident.code}
                    state={incident.status}
                    description={incident.description}
                  />
                </Link>
              </div>
            ))}
          { loading ? <span className="mt-2 text-sm">Cargando incidencias ... </span> : null}
        </div>
        <div className="w-full px-5 fixed bottom-0 inset-x-0 mb-24 p-2 text-center z-10 backdrop-filter backdrop-blur-sm">
          <button
            type="button"
            className="bg-blue-200 text-sm p-4 rounded-xl shadow-md focus:outline-none"
            onClick={() => navigate(ROUTES.incidents)}
          >
            Reportar una incidencia
          </button>
        </div>
      </div>
    </MainLayout>
  );
};

export default IncidentList;
