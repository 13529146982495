import { CLIENT_BASE64, SERVER_URL } from '../../constants/config';
import JSONToURLEncoded from '../../helpers/JSONToURLEncoded';

const DoLogin = async ({ username, password }) => fetch(`${SERVER_URL}/oauth2/token`, {
  method: 'POST',
  headers: {
    Authorization: `Basic ${CLIENT_BASE64}`,
    'Content-type': 'application/x-www-form-urlencoded',
  },
  body: JSONToURLEncoded({
    grant_type: 'password',
    username,
    password,
  }),
});

const DoMe = async () => fetch(`${SERVER_URL}/api/v1/user/me`, {
  method: 'GET',
  headers: {
    accept: '*/*',
    'x-auth-token': localStorage.getItem('access_token'),
    'fiware-service': 'rinconada',
    'fiware-servicepath': '/',
  },
});

const DoRoleMe = async ({ id }) => fetch(`${SERVER_URL}/api/v1/user/${id}/roles`, {
  method: 'GET',
  headers: {
    'x-auth-token': localStorage.getItem('access_token'),
    'fiware-service': 'rinconada',
    'fiware-servicepath': '/',
  },
});

const DoGetTelephone = async ({ id }) => fetch(`${SERVER_URL}/v2/entities?options=keyValues,count&type=usuario&q=userId==${id}`, {
  method: 'GET',
  headers: {
    'x-auth-token': localStorage.getItem('access_token'),
    'fiware-service': 'rinconada',
    'fiware-servicepath': '/',
  },
});

const DoUpdateTelephoneMe = async ({ entityId, telephone }) => fetch(`${SERVER_URL}/v2/entities/${entityId}/attrs?options=keyValues`, {
  method: 'PATCH',
  headers: {
    'x-auth-token': localStorage.getItem('access_token'),
    'content-type': 'application/json',
    'fiware-service': 'rinconada',
    'fiware-servicepath': '/',
  },
  body: JSON.stringify({
    telephone,
  }),
});

const DoUpdateMe = async ({
  id, email, name, surname, role,
}) => fetch(`${SERVER_URL}/api/v1/user/${id}`, {
  method: 'PUT',
  headers: {
    'x-auth-token': localStorage.getItem('access_token'),
    'content-type': 'application/json',
    'fiware-service': 'rinconada',
    'fiware-servicepath': '/',
  },
  body: JSON.stringify({
    id,
    email,
    name,
    surname,
    roles: [role],
  }),
});

const DoUpdateMePassword = async ({ oldPassword, newPassword }) => fetch(`${SERVER_URL}/api/v1/user/me/password/update`, {
  method: 'POST',
  headers: {
    'x-auth-token': localStorage.getItem('access_token'),
    'content-type': 'application/json',
    'fiware-service': 'rinconada',
    'fiware-servicepath': '/',
  },
  body: JSON.stringify({
    original_password: oldPassword,
    password: newPassword,
    repassword: newPassword,
  }),
});

const DoRegistry = async ({
  name, surname, email, telephone, address,
}) => fetch(`${SERVER_URL}/registry`, {
  method: 'POST',
  headers: {
    accept: '*/*',
    'content-type': 'application/json',
    'fiware-service': 'rinconada',
    'fiware-servicepath': '/',
  },
  body: JSON.stringify({
    name,
    surname,
    email,
    telephone,
    address,
  }),
}).catch((res) => console.error('res', res));

const DoResetPassword = async ({
  email,
}) => fetch(`${SERVER_URL}/registry/resetPassword`, {
  method: 'POST',
  headers: {
    accept: '*/*',
    'content-type': 'application/json',
    'fiware-service': 'rinconada',
    'fiware-servicepath': '/',
  },
  body: JSON.stringify({
    email,
  }),
});

export default DoLogin;
export {
  DoLogin, DoRegistry, DoMe, DoRoleMe, DoUpdateMe,
  DoGetTelephone, DoUpdateTelephoneMe,
  DoUpdateMePassword, DoResetPassword,
};
