/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';

import Geocode from 'react-geocode';
import { navigate } from '@reach/router';
import { GOOGLE_MAP_API_KEY } from '../../../constants/config';

import Button from '../../Button';
import UploadPhotoButton from './UploadPhotoButton';

import { LocationIcon } from '../../../assets/icons/Location';
import { LoadIcon } from '../../../assets/icons/Load';

import { useAlertDispatch } from '../../../services/Contexts/Alert';
import { useLocationState } from '../../../services/Contexts/Location';

import ObtainIncidentData from '../Functions/ObtainIncidentData';
import { createIncident } from '../../../services/api/Incidents';
import ROUTES from '../../../routes/routes';
import MainLayout from '../../MainLayout';

const IncidentManagerModal = () => {
  const isPrivate = localStorage.getItem('isRegistered') === 'true';
  const isLogged = localStorage.getItem('access_token') && localStorage.getItem('access_token');
  const dispatchAlert = useAlertDispatch();
  useEffect(() => {
    if (!isPrivate || !isLogged) {
      navigate(ROUTES.dashboard);
      dispatchAlert({
        type: 'enableAlert',
        payload: {
          alertText: 'Debes estar registrado para acceder a este contenido. Si ya lo estaba cierre sesión e inicie de nuevo por favor.',
          alertColor: 'bg-blue-200',
        },
      });
    }
  }, []);
  const { lat, lng } = useLocationState();

  const [addressEnabled, setAddressEnabled] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);

  const [incidentInfo, setIncidentInfo] = useState({
    address: '',
    city: '',
    code: 0,
    coords: {
      lat: 0,
      lng: 0,
    },
    creDate: '',
    description: '',
    executor: '',
    family: '',
    images: [],
    modDate: 0,
    number: 0,
    orderType: '',
    presenterEmail: '',
    presenterName: '',
    presenterSurname: '',
    presenterTelephone: '',
    printed: false,
    status: 'pending',
    street: '',
    subfamily: '',
    type: 'incidencia',
    validated: false,
    zipCode: 0,
    zone: '',
  });
  const getAddressFromLatLng = async ({ latitude, longitude }) => {
    Geocode.setApiKey(GOOGLE_MAP_API_KEY);
    Geocode.fromLatLng(latitude, longitude).then((result) => {
      const {
        street, city, zone, number, zipCode,
      } = ObtainIncidentData(result);
      setIncidentInfo({
        ...incidentInfo,
        coords: {
          lat: latitude,
          lng: longitude,
        },
        address: result.results[0].formatted_address,
        street,
        city,
        zone,
        number,
        zipCode,
        creDate: new Date().getTime(),
        modDate: new Date().getTime(),
      });
    }).catch((error) => error);
    setAddressEnabled(true);
  };

  const createIncidentAsync = async () => {
    await createIncident(incidentInfo).then((result) => {
      if (result.ok) {
        navigate(ROUTES.incidents);
        dispatchAlert({
          type: 'enableAlert',
          payload: {
            alertText: 'Incidencia enviada. Gracias por colaborar con nosotros!',
            alertColor: 'bg-blue-200',
          },
        });
        setDescriptionError(false);
      } else {
        dispatchAlert({ type: 'enableStandardErrorAlert' });
      }
    });
  };

  const handleTextArea = (e) => {
    setIncidentInfo({
      ...incidentInfo,
      description: e.target.value,
    });
  };

  if (!addressEnabled) getAddressFromLatLng({ latitude: lat, longitude: lng });
  if (lat === 0 && lng === 0) navigate(ROUTES.incidents);

  return (
    <MainLayout text="CREAR INCIDENCIA">
      <div className="h-screen w-full flex flex-col items-center sm:justify-center pt-32 standalone:pt-32 smScreen:pt-28">
        <div className="w-full max-w-3xl flex flex-col space-y-3 justify-center items-center px-8 font-body">

          <div className="h-screen w-screen p-10">
            <div className="flex smScreen:mt-3 mt-8 space-x-3 justify-center items-center">
              <LocationIcon />
              <div className="flex flex-col">
                <span className="font-body"> La incidencia ha ocurrido en: </span>
                {incidentInfo.address
                  ? (
                    <span className="font-body text-blue-300">
                      { incidentInfo.address }
                    </span>
                  )
                  : <LoadIcon size={5} />}
              </div>
            </div>
            <div className="smScreen:mt-3 mt-10 flex flex-col space-y-3 justify-center items-center max-w-5xl mx-auto">
              <span className="font-body"> Introduzca una breve descripción de la incidencia: </span>
              <textarea className="smScreen:h-20 h-32 w-full border border-blue-400 rounded-md mt-4 p-3 text-sm focus:outline-none font-body" onChange={handleTextArea} />
              {descriptionError ? <span className="font-body text-sm text-red-400">La descripción es demasiado corta </span> : null}
            </div>
            <UploadPhotoButton incidentInfo={incidentInfo} setIncidentInfo={setIncidentInfo} />
            <div className="flex smScreen:mt-6 mt-10 space-x-3 max-w-5xl mx-auto">
              <Button
                className="bg-blue-300 focus:outline-none"
                onClick={() => {
                  if (incidentInfo.description.length < 20) setDescriptionError(true);
                  createIncidentAsync().then((result) => result).catch((error) => error);
                }}
                text="Enviar"
              />
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default IncidentManagerModal;
