import React from 'react';
import PropTypes from 'prop-types';

const UserIcon = ({ size = 9 }) => (
  <svg className={`w-${size} h-${size} text-blue-300`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" /></svg>
);

UserIcon.propTypes = {
  size: PropTypes.number,
};

UserIcon.defaultProps = {
  size: 9,
};

export default UserIcon;
export { UserIcon };
