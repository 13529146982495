import React from 'react';
import { navigate } from '@reach/router';
import ROUTES from '../../routes/routes';

const UnRegistered = () => (
  <div className="h-screen w-full flex flex-col items-center sm:justify-center pt-36 standalone:pt-44 smScreen:pt-40">
    <div className="w-full max-w-3xl flex flex-col space-y-3 justify-center items-center px-8 font-body">
      <div className="w-full flex flex-col smScreen:flex-row smScreen:mt-10 smScreen:px-4 smScreen:space-y-0 smScreen:space-x-2 pb-32 space-y-3 justify-center items-center mt-10">
        <button
          type="button"
          className="w-2/3 max-w-lg mx-auto rounded-md h-16 bg-blue-100 font-body"
          onClick={() => {
            localStorage.removeItem('isRegistered');
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            navigate(ROUTES.login);
          }}
        >
          Acceder o crear cuenta
        </button>
      </div>
    </div>
  </div>
);

export default UnRegistered;
