import React from 'react';
import PropTypes from 'prop-types';

const LocationState = React.createContext();
const LocationDispatch = React.createContext();

const LocationReducer = (state, action) => {
  switch (action.type) {
    case 'saveLocation':
      return {
        ...state,
        lat: action.payload.lat,
        lng: action.payload.lng,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const LocationProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(LocationReducer, {
    lat: 0,
    lng: 0,
  });
  return (
    <LocationState.Provider value={state}>
      <LocationDispatch.Provider value={dispatch}>
        {children}
      </LocationDispatch.Provider>
    </LocationState.Provider>
  );
};

const useLocationState = () => {
  const context = React.useContext(LocationState);
  if (context === undefined) {
    throw new Error('Location context must be used within a provider');
  }
  return context;
};

const useLocationDispatch = () => {
  const context = React.useContext(LocationDispatch);
  if (context === undefined) {
    throw new Error('Location dispatch must be used within a provider');
  }
  return context;
};

LocationProvider.propTypes = {
  children: PropTypes.element,
};

LocationProvider.defaultProps = {
  children: {},
};

export { LocationProvider, useLocationState, useLocationDispatch };
