import React from 'react';
import PropTypes from 'prop-types';

import CouncilLogo from '../../../assets/images/simple-logo-rinconada.png';
import EuropeLogo from '../../../assets/images/logo-union-europea.png';

const index = ({ text }) => (
  <div className="flex justify-center items-center space-x-2 pt-3 fixed top-0 standalone:pt-8 inset-x-0 h-32 standalone:h-36 max-w-5xl mx-auto text-black rounded-b-3xl bg-white font-body z-10 border-b border-gray-200 shadow-md">
    <img src={CouncilLogo} alt="logo" className="object-contain h-24 w-full pt-2 mb-5" />
    <h1 className="font-body text-center text-xl text-gray-400">
      {text}
    </h1>
    <img src={EuropeLogo} alt="logo" className="object-contain h-20 w-full pt-5 mb-5" />
  </div>
);

index.propTypes = {
  text: PropTypes.string,
};

index.defaultProps = {
  text: '',
};

export default index;
