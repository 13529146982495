import React from 'react';
import { PropTypes } from 'prop-types';

const IncidentDetailElement = (props) => {
  const { element, text } = props;
  return (
    <div className="w-full flex justify-start sm:justify-center items-center">
      <p className="text-xs text-gray-400">
        <span className="font-bold sm:text-lg">
          {' '}
          {text}
          {' '}
        </span>
        <span className="ml-1 sm:text-lg">
          {element}
        </span>
      </p>
    </div>
  );
};

IncidentDetailElement.propTypes = {
  text: PropTypes.string,
  element: PropTypes.string,
};

IncidentDetailElement.defaultProps = {
  text: '',
  element: '',
};

export default IncidentDetailElement;
