import React, { useState, useEffect } from 'react';
import { DownArrowIcon } from '../../assets/icons/DownArrow';
import SafariIcon from '../../assets/images/safari_share.png';

const InstallationPopUp = () => {
  const [showPopUp, setShowPopUp] = useState();
  useEffect(() => {
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };
    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);
    if (isIos() && !isInStandaloneMode()) setShowPopUp(true);
    else setShowPopUp(false);
  }, []);

  return (
    <>
      {showPopUp
        ? (
          <div
            className="absolute bottom-0 inset-x-0 font-body text-sm"
            role="button"
            onKeyDown={() => null}
            tabIndex={0}
            onClick={() => setShowPopUp(false)}
          >
            <div className="p-5">
              <div className="flex flex-col justify-center items-center space-y-3">
                <div className="bg-blue-200 p-5 rounded-md text-center shadow-md flex flex-col justify-center items-center">
                  <div className="flex space-x-2 pb-1">
                    <span className=""> Presiona </span>
                    <img alt="safari" src={SafariIcon} className="w-4 h-5" />
                    <span className=""> y </span>
                    <span className="font-semibold smScreen:text-xs smScreen:pt-1"> Añadir a pantalla de incio</span>
                  </div>
                  <span className="pt-1"> para instalar la aplicación en tu móvil.</span>
                </div>
                <div>
                  <DownArrowIcon />
                </div>
              </div>
            </div>
          </div>
        )
        : null}
    </>
  );
};

export default InstallationPopUp;
