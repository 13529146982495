import React from 'react';
import MainLayout from '../../../components/MainLayout';

const GeneralInformation = () => (
  <MainLayout text="TELÉFONOS E INFORMACIÓN DE INTERÉS">
    <div className="h-screen p-5 w-screen max-w-4xl mx-auto flex flex-col pt-40 standalone:pt-44 items-center smScreen:mt-2 sm:mt-8" style={{ WebkitTapHighlightColor: 'transparent' }}>
      <div className="container mx-auto smScreen:mt-5 mt-2 smScreen:pb-5 pb-2">
        <div className="flex flex-col space-y-2 font-body">
          <h1 className="font-bold"> Teléfonos de interés </h1>
          <div className="px-3 space-y-2 pb-5">
            <table className="table-auto">
              <tbody>
                <tr>
                  <td>Alzheimer La Rinconada</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955381863">955381863</a></td>
                </tr>
                <tr>
                  <td>Ayuntamiento</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955797000">955797000</a></td>
                </tr>
                <tr>
                  <td>Ayuntamiento -Almacén mpal Rinconada-</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34954737010">954737010</a></td>
                </tr>
                <tr>
                  <td>Ayuntamiento -Almacén mpal San José-</td>
                  <td>
                    <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34954795617">954795617</a>
                    /
                    <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955790313">955790313</a>
                  </td>
                </tr>
                <tr>
                  <td>Ayuntamiento -Bienestar Social Rinconada-</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955798406">955798406</a></td>
                </tr>
                <tr>
                  <td>Ayuntamiento -Bienestar Social San José-</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34954790200">954790200</a></td>
                </tr>
                <tr>
                  <td>Ayuntamiento -Recursos Humanos-</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955797747">955797747</a></td>
                </tr>
                <tr>
                  <td>Biblioteca Hacienda Santa Cruz</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955544115">955544115</a></td>
                </tr>
                <tr>
                  <td>Biblioteca Rinconada</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955798104">955798104</a></td>
                </tr>
                <tr>
                  <td>Biblioteca San José</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955794151">955794151</a></td>
                </tr>
                <tr>
                  <td>Bomberos</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34954795806">954795806</a></td>
                </tr>
                <tr>
                  <td>CEIP Blanca de los Ríos</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955624497">955624497</a></td>
                </tr>
                <tr>
                  <td>CEIP Guadalquivir</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955622703">955622703</a></td>
                </tr>
                <tr>
                  <td>CEIP Júpiter</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955656801">955656801</a></td>
                </tr>
                <tr>
                  <td>CEIP La Paz</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955622136">955622136</a></td>
                </tr>
                <tr>
                  <td>CEIP La Unión</td>
                  <td>
                    <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955541118">955541118</a>
                    /
                    <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34652139030">652139030</a>
                  </td>
                </tr>
                <tr>
                  <td>CEIP Los Azahares</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955622145">955622145</a></td>
                </tr>
                <tr>
                  <td>CEIP Maestro Antonio Rodríguez</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955623140">955623140</a></td>
                </tr>
                <tr>
                  <td>CEIP Maestro Pepe González</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955622356">955622356</a></td>
                </tr>
                <tr>
                  <td>CEIP Nuestra Señora de Patrocinio (PÚA)</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955623975">955623975</a></td>
                </tr>
                <tr>
                  <td>Cementerios</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955794186">955794186</a></td>
                </tr>
                <tr>
                  <td>Centro Cívico ‘Los Silos’</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34974417385">974417385</a></td>
                </tr>
                <tr>
                  <td>Centro Comarcal de Drogodependencias</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34954790032">954790032</a></td>
                </tr>
                <tr>
                  <td>Centro Cultural Antonio Gala</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955798104">955798104</a></td>
                </tr>
                <tr>
                  <td>Centro Cultural de La Villa</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955794142">955794142</a></td>
                </tr>
                <tr>
                  <td>Centro de Adultos ‘Cerro Macareno’</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34970941662">970941662</a></td>
                </tr>
                <tr>
                  <td>Centro de Coordinación de Emergencias</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955042112">955042112</a></td>
                </tr>
                <tr>
                  <td>Centro de Formación Inteja</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955792462">955792462</a></td>
                </tr>
                <tr>
                  <td>Centro Joven ‘La Estación’</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34954793900">954793900</a></td>
                </tr>
                <tr>
                  <td>Centro Juan Pérez Mercader (Soderin)</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955790570">955790570</a></td>
                </tr>
                <tr>
                  <td>Centro Municipal de Información a la Mujer</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955790534">955790534</a></td>
                </tr>
                <tr>
                  <td>Centro Ocupacional Torrepavas</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34954793100">954793100</a></td>
                </tr>
                <tr>
                  <td>Colegio San José (Convento)</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34954790040">954790040</a></td>
                </tr>
                <tr>
                  <td>Comunidad de Regantes</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34954211315">954211315</a></td>
                </tr>
                <tr>
                  <td>Confederación Hidrográfica del Guadalquivir</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34954939400">954939400</a></td>
                </tr>
                <tr>
                  <td>Conservatorio ‘Miguel Hernández Garrido’</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955791204">955791204</a></td>
                </tr>
                <tr>
                  <td>Correos Rinconada</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955797558">955797558</a></td>
                </tr>
                <tr>
                  <td>Correos San José</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34954790133">954790133</a></td>
                </tr>
                <tr>
                  <td>Diputación Provincial de Sevilla</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34954550000">954550000</a></td>
                </tr>
                <tr>
                  <td>Emasesa</td>
                  <td>
                    <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955477802">955477802</a>
                    /
                    <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955010010">955010010</a>
                  </td>
                </tr>
                <tr>
                  <td>Endesa</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955498770">955498770</a></td>
                </tr>
                <tr>
                  <td>Escuela Infantil Almonazar</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34954792292">954792292</a></td>
                </tr>
                <tr>
                  <td>Escuela Infantil Gloria Fuertes</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955797060">955797060</a></td>
                </tr>
                <tr>
                  <td>Escuela Infantil Santa Cruz</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955940236">955940236</a></td>
                </tr>
                <tr>
                  <td>Estadio Felipe del Valle</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955794053">955794053</a></td>
                </tr>
                <tr>
                  <td>Guardia Civil</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34954790013">954790013</a></td>
                </tr>
                <tr>
                  <td>Hogar del Pensionista La Rinconada</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955797262">955797262</a></td>
                </tr>
                <tr>
                  <td>Hogar del Pensionista San José</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955794062">955794062</a></td>
                </tr>
                <tr>
                  <td>IES Antonio de Ulloa</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955622611">955622611</a></td>
                </tr>
                <tr>
                  <td>IES Carmen Laffón</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955623388">955623388</a></td>
                </tr>
                <tr>
                  <td>IES Miguel de Mañara</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34954790288">954790288</a></td>
                </tr>
                <tr>
                  <td>IES San José</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955622342">955622342</a></td>
                </tr>
                <tr>
                  <td>INEM</td>
                  <td>
                    <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955625695">955625695</a>
                    /
                    <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34902100506">902100506 (Cita Previa)</a>
                  </td>
                </tr>
                <tr>
                  <td>ITV</td>
                  <td>
                    <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955624641">955624641</a>
                    /
                    <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34959999999">959999999 (Cita Previa)</a>
                  </td>
                </tr>
                <tr>
                  <td>Jefatura Provincial de Tráfico</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34954245300">954245300</a></td>
                </tr>
                <tr>
                  <td>Juzgado de Paz</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955797803">955797803</a></td>
                </tr>
                <tr>
                  <td>Oficina Mpal de Información al Consumidor</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34927497327">927497327</a></td>
                </tr>
                <tr>
                  <td>OPAEF</td>
                  <td>
                    <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34954795651">954795651</a>
                    /
                    <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34900700100">900700100</a>
                  </td>
                </tr>
                <tr>
                  <td>Pabellón Agustín Andrade ‘Gorri’</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955798356">955798356</a></td>
                </tr>
                <tr>
                  <td>Pabellón Fernando Martín</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955791527">955791527</a></td>
                </tr>
                <tr>
                  <td>Parroquia Nuestra Señora de las Nieves</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955797833">955797833</a></td>
                </tr>
                <tr>
                  <td>Parroquia San José</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34954793053">954793053</a></td>
                </tr>
                <tr>
                  <td>Parroquia Santa María Madre de Dios</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955790823">955790823</a></td>
                </tr>
                <tr>
                  <td>Patronato de Deportes</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955700541">955700541</a></td>
                </tr>
                <tr>
                  <td>Policía Local</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955794088">955794088</a></td>
                </tr>
                <tr>
                  <td>Polideportivo ‘El Santísimo’</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955798960">955798960</a></td>
                </tr>
                <tr>
                  <td>Polideportivo Francisco Sánchez ‘Castañita’</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34954790520">954790520</a></td>
                </tr>
                <tr>
                  <td>Protección Civil</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34954795959">954795959</a></td>
                </tr>
                <tr>
                  <td>Radio Rinconada</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34970302722">970302722</a></td>
                </tr>
                <tr>
                  <td>Registro de la Propiedad</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34954793976">954793976</a></td>
                </tr>
                <tr>
                  <td>Renfe</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34902240202">902240202</a></td>
                </tr>
                <tr>
                  <td>Salud Mental</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955790598">955790598</a></td>
                </tr>
                <tr>
                  <td>Servicio Andaluz de Empleo (SAE)</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955656672">955656672</a></td>
                </tr>
                <tr>
                  <td>Servicio de Atención al Ciudadano (SAC)</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34954792413">954792413</a></td>
                </tr>
                <tr>
                  <td>Tenencia de Alcaldía</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955794300">955794300</a></td>
                </tr>
                <tr>
                  <td>Vitalia Rinconada</td>
                  <td><a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="tel:+34955790675">955790675</a></td>
                </tr>
              </tbody>
            </table>
          </div>
          <h1 className="font-bold">Horario parques (invierno)</h1>
          <div className="px-3 space-y-2">
            <h1 className="font-light text-sm">De 7:30 a 22:00 horas</h1>
          </div>
        </div>
      </div>
    </div>
  </MainLayout>
);

export default GeneralInformation;
