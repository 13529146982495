import React from 'react';
import PropTypes from 'prop-types';
import { LoadIcon } from '../../assets/icons/Load';

const Button = ({
  onClick,
  text,
  className,
  loading,
  disabled,
}) => (
  <button
    className={`flex justify-center items-center w-full p-4 rounded text-black font-body ${className}`}
    type="button"
    onClick={onClick}
    disabled={disabled}
  >
    {loading ? <LoadIcon size={5} /> : null}
    {text}
  </button>
);

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  className: '',
  loading: false,
  disabled: false,
};

export default Button;
