import React from 'react';

const BusIcon = () => (
  <svg className="w-9 h-9 text-blue-300" fill="currentColor" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <g
      transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
      stroke="none"
    >
      <path d="M123 985 c-46 -20 -63 -60 -63 -149 0 -74 0 -75 -27 -78 l-28 -3 0
-115 0 -115 27 -3 28 -3 0 -194 c0 -118 4 -196 10 -200 6 -3 10 -28 10 -54 0
-26 5 -52 12 -59 16 -16 160 -16 176 0 7 7 12 25 12 40 l0 28 220 0 220 0 0
-28 c0 -15 5 -33 12 -40 16 -16 160 -16 176 0 7 7 12 33 12 59 0 26 4 51 10
54 6 4 10 82 10 200 l0 194 28 3 27 3 0 115 0 115 -27 3 c-28 3 -28 4 -28 78
0 81 -14 122 -52 148 -19 14 -72 16 -378 16 -280 -1 -362 -4 -387 -15z m729
-39 c39 -18 43 -56 43 -428 0 -330 -1 -359 -18 -375 -16 -17 -45 -18 -377 -18
-331 0 -361 1 -377 18 -16 16 -18 46 -21 325 -5 448 -1 469 94 483 65 9 635 6
656 -5z"
      />
      <path d="M264 926 c-3 -7 -4 -33 -2 -57 l3 -44 245 0 245 0 0 55 0 55 -243 3
c-201 2 -243 0 -248 -12z m456 -46 c0 -19 -7 -20 -210 -20 -203 0 -210 1 -210
20 0 19 7 20 210 20 203 0 210 -1 210 -20z"
      />
      <path d="M157 782 c-14 -16 -17 -44 -17 -184 0 -147 2 -167 18 -181 17 -15 55
-17 339 -17 l320 0 21 23 c21 22 22 33 22 184 0 142 -2 162 -18 176 -17 15
-55 17 -344 17 -302 0 -326 -1 -341 -18z m650 -35 c15 -15 16 -260 1 -279 -11
-14 -47 -17 -310 -20 -163 -1 -300 1 -305 5 -10 11 -17 217 -9 262 4 20 14 36
24 38 30 9 590 2 599 -6z"
      />
      <path d="M165 315 c-35 -34 -35 -96 0 -130 18 -19 35 -25 65 -25 56 0 90 34
90 90 0 56 -34 90 -90 90 -30 0 -47 -6 -65 -25z"
      />
      <path d="M705 315 c-19 -18 -25 -35 -25 -65 0 -56 34 -90 90 -90 56 0 90 34
90 90 0 56 -34 90 -90 90 -30 0 -47 -6 -65 -25z"
      />
    </g>
  </svg>
);

export default BusIcon;
export { BusIcon };
