import React from 'react';
import MainLayout from '../../../components/MainLayout';
import { DocumentIcon } from '../../../assets/icons/Document';

const BusScheduleConsorcio = () => (
  <MainLayout text="HORARIOS AUTOBUSES">
    <div className="h-screen p-5 w-screen max-w-4xl mx-auto flex flex-col pt-40 standalone:pt-44 items-center smScreen:mt-2 sm:mt-8" style={{ WebkitTapHighlightColor: 'transparent' }}>
      <div className="container mx-auto smScreen:mt-5 mt-2 smScreen:pb-5 pb-2">
        <div className="flex flex-col space-y-2 font-body">
          <h1 className="font-bold"> M-111 </h1>
          <div className="px-3 space-y-2 pb-5">
            <h1 className="font-light text-sm"> San José de la Rinconada - Sevilla (por el Gordillo) </h1>
            <div className="border border-blue-300 p-3 rounded-md flex justify-between items-center">
              <a href="/Docs/M-111-1.pdf" target="_black" className="text-sm"> Pulse para abrir PDF </a>
              <DocumentIcon />
            </div>
            <h1 className="font-light text-sm"> San José de la Rinconada - Sevilla (directo ida) </h1>
            <div className="border border-blue-300 p-3 rounded-md flex justify-between items-center">
              <a href="/Docs/M-111-2.pdf" target="_black" className="text-sm"> Pulse para abrir PDF </a>
              <DocumentIcon />
            </div>
          </div>
          <h1 className="font-bold"> M-112 </h1>
          <div className="px-3 space-y-2">
            <h1 className="font-light text-sm"> San José de la Rinconada - Sevilla (por Rinconada) </h1>
            <div className="border border-blue-300 p-3 rounded-md flex justify-between items-center">
              <a href="/Docs/M-112-1.pdf" target="_black" className="text-sm"> Pulse para abrir PDF </a>
              <DocumentIcon />
            </div>
            <h1 className="font-light text-sm"> La Rinconada - Sevilla (directo) </h1>
            <div className="border border-blue-300 p-3 rounded-md flex justify-between items-center">
              <a href="/Docs/M-112-2.pdf" target="_black" className="text-sm"> Pulse para abrir PDF </a>
              <DocumentIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  </MainLayout>
);

export default BusScheduleConsorcio;
