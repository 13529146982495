import React from 'react';

import { navigate } from '@reach/router';
import MainLayout from '../../components/MainLayout';

const index = () => (
  <MainLayout text="COMPRAR ENTRADAS">
    <div className="w-full h-screen p-10 flex justify-center items-center">
      <div
        className="bg-blue-100 w-full max-w-lg h-24 flex justify-center items-center rounded shadow-md p-3"
        role="button"
        onClick={() => navigate('https://entradas.larinconada.es')}
        onKeyDown={() => null}
        tabIndex={0}
      >
        <p className="text-center font-body">
          <span className="font-bold"> PINCHA AQUI </span>
          PARA COMPRAR TUS ENTRADAS
        </p>
      </div>
    </div>
  </MainLayout>
);

export default index;
