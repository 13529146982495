import React from 'react';

const PharmacyIcon = () => (
  <svg className="w-9 h-9 text-blue-300" fill="currentColor" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <g
      transform="translate(0.000000,100.000000) scale(0.250000,-0.250000)"
      stroke="none"
    >
      <path d="M249.39,169.804h-39.451v-39.449c0-5.523-4.478-10-10-10h-47.221c-5.522,0-10,4.477-10,10v39.449h-39.451
c-5.522,0-10,4.477-10,10v47.224c0,5.523,4.478,10,10,10h39.451v39.45c0,5.523,4.478,10,10,10h47.221c5.522,0,10-4.477,10-10v-39.45
h39.451c5.522,0,10-4.477,10-10v-47.224C259.39,174.281,254.913,169.804,249.39,169.804z M239.39,217.028h-39.451
c-5.522,0-10,4.477-10,10v39.45h-27.221v-39.45c0-5.523-4.478-10-10-10h-39.451v-27.224h39.451c5.522,0,10-4.477,10-10v-39.449
h27.221v39.449c0,5.523,4.478,10,10,10h39.451V217.028z M385.57,140.499c0.001-0.065,0.002-0.131,0.002-0.196
s-0.001-0.131-0.002-0.196V10c0-5.523-4.478-10-10-10s-10,4.477-10,10v120.303h-34.174V85.64c0-20.563-16.729-37.292-37.291-37.292
H58.554c-20.563,0-37.293,16.729-37.293,37.292v235.552c0,20.563,16.729,37.292,37.293,37.292h235.551
c20.563,0,37.291-16.729,37.291-37.292v-44.664h34.174v120.305c0,5.523,4.478,10,10,10s10-4.477,10-10V266.725
c0.001-0.065,0.002-0.131,0.002-0.196s-0.001-0.131-0.002-0.196V140.499z M311.396,321.192c0,9.535-7.757,17.292-17.291,17.292
H58.554c-9.535,0-17.293-7.757-17.293-17.292V85.64c0-9.535,7.758-17.292,17.293-17.292h235.551c9.534,0,17.291,7.757,17.291,17.292
V321.192z M365.57,256.528h-34.174V150.303h34.174V256.528z"
      />
    </g>
  </svg>
);

export default PharmacyIcon;
export { PharmacyIcon };
