import React from 'react';
import PropTypes from 'prop-types';

const AuthState = React.createContext();
const AuthDispatch = React.createContext();

const authReducer = (state, action) => {
  switch (action.type) {
    case 'login':
      return {
        ...state,
        access_token: action.payload.access_token,
        refresh_token: action.payload.refresh_token,
        email: action.payload.email,
        id: action.payload.id,
        error: false,
      };

    case 'me':
      return {
        ...state,
        id: action.payload.id,
        name: action.payload.name,
        surname: action.payload.surname,
        email: action.payload.email,
        telephone: action.payload.telephone,
      };

    case 'error':
      return {
        ...state,
        error: true,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const AuthProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(authReducer, {
    error: false,
    access_token: null,
    refresh_token: null,
    id: '',
    name: '',
    surname: '',
    email: '',
    telephone: '',
  });
  return (
    <AuthState.Provider value={state}>
      <AuthDispatch.Provider value={dispatch}>
        {children}
      </AuthDispatch.Provider>
    </AuthState.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.element,
};

AuthProvider.defaultProps = {
  children: {},
};

const useAuthState = () => {
  const context = React.useContext(AuthState);
  if (context === undefined) {
    throw new Error('Auth context must be used within a provider');
  }
  return context;
};

const useAuthDispatch = () => {
  const context = React.useContext(AuthDispatch);
  if (context === undefined) {
    throw new Error('Auth dispatch must be used within a provider');
  }
  return context;
};

export { AuthProvider, useAuthState, useAuthDispatch };
