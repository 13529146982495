import { SERVER_URL } from '../../constants/config';

const orionRegexRestricctions = /[&/\\#,+()$~%'":;*?<>{}]/g;

const createIncident = async (incident) => {
  const newIncident = incident;
  Object.keys(incident).forEach((key) => {
    if (typeof incident[key] === 'string') newIncident[key] = incident[key].replace(orionRegexRestricctions, '');
  });
  return fetch(`${SERVER_URL}/v2/entities?options=keyValues`, {
    method: 'POST',
    headers: {
      'fiware-service': 'rinconada',
      'fiware-servicepath': '/',
      'x-auth-token': localStorage.getItem('access_token'),
      'Content-type': 'application/json',
    },
    body: JSON.stringify(newIncident),
  }).catch((error) => console.error('error:', error));
};

const getIncidents = async (userId, numIncs, page = 0) => fetch(`${SERVER_URL}/api/v1/historical/search?x-query-page=${page}&x-query-size=${numIncs}&x-query-sort-recvTime=desc&operation=incidencia:crear&operator=${userId}`, {
  method: 'GET',
  headers: {
    'x-auth-token': localStorage.getItem('access_token'),
    'x-fiware-service': 'events_rinconada',
    'x-fiware-servicepath': 'events_/',
  },
}).catch((error) => console.error('error:', error));

const getIncidentStatus = async (incidentId) => fetch(`${SERVER_URL}/api/v1/historical/search?x-query-page=1&x-query-size=1&x-query-sort-recvTime=desc&entityId=${incidentId}`, {
  method: 'GET',
  headers: {
    'x-auth-token': localStorage.getItem('access_token'),
    'x-fiware-service': 'events_rinconada',
    'x-fiware-servicepath': 'events_/',
  },
}).catch((error) => console.error('error:', error));

export default createIncident;
export { createIncident, getIncidents, getIncidentStatus };
