const CLIENT_BASE64 = process.env.REACT_APP_CLIENT_BASE64 ?? 'NTlmM2Y1NjMtY2E3ZC00OTg0LWE2ZmItMzIyMmMwZWUyZWQ3OjA2YmQ4YzIyLTBmYzUtNDgyYS05ZjI2LTA0ZmQxNzJlYjUxNQ==';
const SERVER_URL = process.env.REACT_APP_SERVER_URL ?? 'https://development-rinconada.secmotic.com';
const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY ?? 'AIzaSyB5LRAYDyykGVvG0PB96EkA_K2DLy94urU';
const RINCONADA_LATITUDE = 37.4873;
const RINCONADA_LONGITUDE = -5.9816;

export {
  CLIENT_BASE64, SERVER_URL, GOOGLE_MAP_API_KEY, RINCONADA_LATITUDE,
  RINCONADA_LONGITUDE,
};
