import React from 'react';

const ParkIcon = () => (
  <svg className="w-9 h-9 text-blue-400" fill="currentColor" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <g
      transform="translate(0.000000,100.000000) scale(0.18000,-0.18000) rotate(-180 250 250)"
      stroke="none"
    >
      <path d="M220.218,244.528c1.099,0.517,2.275,0.771,3.444,0.771c1.844,0,3.671-0.631,5.149-1.851
c9.135-7.545,20.705-11.701,32.578-11.701c9.113,0,18.072,2.436,25.906,7.042c2.102,1.237,4.649,1.459,6.934,0.603
c2.284-0.855,4.059-2.696,4.832-5.009c4.581-13.712,14.812-24.905,28.07-30.707c4.091-1.79,5.955-6.558,4.165-10.647
c-1.79-4.091-6.557-5.953-10.647-4.165c-14.602,6.391-26.414,17.755-33.408,31.874c-8.16-3.392-16.951-5.159-25.852-5.159
c-10.323,0-20.474,2.389-29.642,6.876v-25.739c0-20.807-9.392-40.121-25.767-52.992c-3.51-2.758-8.593-2.151-11.351,1.36
c-2.758,3.511-2.149,8.592,1.36,11.351c12.45,9.785,19.59,24.467,19.59,40.28v40.499
C215.579,240.346,217.387,243.194,220.218,244.528z"
      />
      <path d="M303.068,37.726h60.721c7.43,0,13.474,6.044,13.474,13.474s-6.044,13.474-13.474,13.474H245.221
c-7.43,0-13.474-6.044-13.474-13.474c0-4.466-3.62-8.084-8.084-8.084h-40.96c-4.465,0-8.084,3.619-8.084,8.084
s3.62,8.084,8.084,8.084H216.7c3.527,12.428,14.978,21.558,28.521,21.558h118.568c16.345,0,29.642-13.298,29.642-29.642
s-13.297-29.642-29.642-29.642h-60.721c-4.465,0-8.084,3.618-8.084,8.084C294.984,34.108,298.604,37.726,303.068,37.726z"
      />
      <path d="M503.916,495.832h-2.695V315.284c0-27.739-17.402-53.032-43.301-62.94c-4.17-1.593-8.845,0.492-10.439,4.662
c-1.595,4.171,0.492,8.845,4.662,10.439c19.685,7.53,32.91,26.754,32.91,47.839v180.547h-48.966
c0.299-0.843,0.46-1.749,0.46-2.695v-358.4H460.8c7.43,0,13.474,6.044,13.474,13.474v14.594
c-12.428,3.528-21.558,14.979-21.558,28.522c0,16.344,13.297,29.642,29.642,29.642c16.345,0,29.642-13.298,29.642-29.642
c0-13.544-9.13-24.995-21.558-28.522V148.21c0-16.344-13.297-29.642-29.642-29.642h-64.674
c-16.345,0-29.642,13.298-29.642,29.642v14.594c-12.428,3.528-21.558,14.979-21.558,28.522c0,16.344,13.297,29.642,29.642,29.642
s29.642-13.298,29.642-29.642c0-13.544-9.13-24.995-21.558-28.522V148.21c0-7.43,6.044-13.474,13.474-13.474h24.253v114.532
c-1.816,0.37-3.613,0.815-5.39,1.333c-0.001-8.483-1.561-16.757-4.636-24.593c-1.63-4.156-6.322-6.207-10.478-4.572
c-4.156,1.631-6.203,6.323-4.572,10.478c2.335,5.95,3.518,12.241,3.518,18.696v11.874c0,2.814,1.464,5.427,3.865,6.895
c2.402,1.469,5.394,1.581,7.9,0.302c3.147-1.61,6.422-2.879,9.793-3.8v227.255c0,0.945,0.163,1.852,0.46,2.695h-70.523v-37.726
h29.642c4.465,0,8.084-3.619,8.084-8.084c0-4.466-3.62-8.084-8.084-8.084h-29.642v-16.168h18.863
c10.401,0,18.863-8.463,18.863-18.863v-43.116c0-10.401-8.463-18.863-18.863-18.863H142.821
c-10.401,0-18.863,8.463-18.863,18.863v43.116c0,10.401,8.463,18.863,18.863,18.863h18.863v16.168h-29.642
c-4.465,0-8.084,3.618-8.084,8.084c0,4.466,3.62,8.084,8.084,8.084h29.642v37.726h-53.895V115.874
c0-4.466-3.62-8.084-8.084-8.084c-4.465,0-8.084,3.618-8.084,8.084v261.389H55.053c-9.927,0-19.093-4.028-25.807-11.342
c-6.714-7.314-9.945-16.789-9.096-26.681L43.412,67.842c2.526-29.459,26.726-51.673,56.293-51.673s53.768,22.214,56.293,51.673
l21.915,255.675c0.381,4.448,4.298,7.74,8.745,7.364c4.448-0.38,7.746-4.296,7.364-8.745L172.107,66.461
c-1.559-18.18-9.821-34.987-23.262-47.326C135.404,6.796,117.952,0,99.705,0c-18.247,0-35.698,6.796-49.14,19.135
C37.123,31.473,28.862,48.281,27.303,66.461L4.041,337.859c-1.221,14.249,3.624,28.463,13.295,38.996
c9.67,10.535,23.417,16.577,37.718,16.577h36.568v102.4H26.947v-88.926c0-4.466-3.62-8.084-8.084-8.084
c-4.465,0-8.084,3.618-8.084,8.084v88.926H8.084C3.62,495.832,0,499.45,0,503.916C0,508.382,3.62,512,8.084,512h259.773
c4.465,0,8.084-3.618,8.084-8.084c0-4.466-3.62-8.084-8.084-8.084h-90.004v-37.726h156.295v37.726h-33.595
c-4.465,0-8.084,3.619-8.084,8.084c0,4.466,3.62,8.084,8.084,8.084h203.363c4.465,0,8.084-3.618,8.084-8.084
C512,499.45,508.38,495.832,503.916,495.832z M482.358,177.853c7.43,0,13.474,6.044,13.474,13.474
c0,7.43-6.044,13.474-13.474,13.474s-13.474-6.044-13.474-13.474C468.884,183.896,474.928,177.853,482.358,177.853z
M388.042,191.326c0,7.43-6.044,13.474-13.474,13.474s-13.474-6.044-13.474-13.474c0-7.43,6.044-13.474,13.474-13.474
S388.042,183.896,388.042,191.326z M334.147,441.937H177.853v-16.168h156.295V441.937z M142.821,409.6
c-1.485,0-2.695-1.208-2.695-2.695v-43.116c0-1.486,1.209-2.695,2.695-2.695h226.358c1.485,0,2.695,1.208,2.695,2.695v43.116
c0,1.486-1.209,2.695-2.695,2.695h-26.947H142.821z"
      />
      <path d="M169.229,16.168H256c7.43,0,13.474,6.044,13.474,13.474c0,4.466,3.62,8.084,8.084,8.084c4.465,0,8.084-3.618,8.084-8.084
C285.642,13.298,272.345,0,256,0h-86.771c-4.465,0-8.084,3.619-8.084,8.084S164.765,16.168,169.229,16.168z"
      />
    </g>
  </svg>
);

export default ParkIcon;
export { ParkIcon };
