import React from 'react';
import PropTypes from 'prop-types';

import { LocationIcon } from '../../../assets/icons/Location';

const GetUserLocationButton = ({ panTo }) => (
  <button
    type="button"
    className="locate focus:outline-none ml-2"
    onClick={() => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          panTo({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        () => null,
      );
    }}
  >
    <LocationIcon />
  </button>
);

GetUserLocationButton.propTypes = {
  panTo: PropTypes.func.isRequired,
};

export default GetUserLocationButton;
